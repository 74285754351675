/** @jsxImportSource @emotion/react */
import React from 'react'
import { loaderWrapperStyle } from './style'

const Loader = ({ showText = false }) => {
  return (
    <div
      style={{
        width: 'calc(100% - 155px)',
        background: '#ffffff80',
        marginLeft: '135px',
        marginRight: '20px',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}
    >
      {showText && <p>Please wait while we load your data</p>}
      <div css={loaderWrapperStyle} className='spinner'>
        <div class='bounce1'></div>
        <div class='bounce2'></div>
        <div class='bounce3'></div>
      </div>
    </div>
  )
}

export default Loader
