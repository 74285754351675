/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'

import Checkbox from '../../../../ui/Checkbox/Checkbox'
import Dropdown from '../../../../ui/Dropdown/Dropdown'

import { channelSourceList } from '../../../../../constant/ads'

import {
  content,
  campaignContainer,
  adContainer,
  previewContainer,
  inputStyle,
  inputWithCurrencyStyle,
  channelContainer,
  label,
  channelLabel,
  spreadAcrossChannelOption,
  dateContainer,
  adNameContainer,
  textContainer,
  headlineContainer,
  newsfeedDescriptionContainer,
  displayLinkContainer,
  callToActionDropdownStyle,
  previewDropdownContainer,
  previewDropdownStyle,
  previewDesktopDropdownStyle,
  channel,
  blank,
  charCountError,
  charCountStyle,
  errorBorderStyle,
  borderStyle,
  errorMessageStyle,
  pageIdContainer
} from './style'
import { useSelector } from 'react-redux'
import { CALL_TO_ACTION } from '../../../../../constant/ads'
import { validateURL } from '../../AdCampaign/adsHelper'

const CharacterLimitInput = ({
  maxChars,
  type = 'text',
  fieldName = '',
  value = '',
  onChange,
  validityFields
}) => {
  const [charCount, setCharCount] = useState(maxChars)

  useEffect(() => {
    setCharCount(maxChars - value.length)
  }, [value])

  return (
    <div>
      <input
        type={type}
        data-isInvalid={validityFields.includes(fieldName)}
        value={value}
        css={charCount < 0 ? errorBorderStyle : borderStyle}
        className='textboxStyle'
        onChange={onChange}
      />
      <div css={charCount < 0 ? charCountError : charCountStyle}>
        {charCount >= 0
          ? `${charCount} characters remaining`
          : `Character limit exceeded by ${-charCount}`}
      </div>
    </div>
  )
}

const CharacterLimitTextArea = ({
  maxChars,
  type = 'text',
  fieldName = '',
  value = '',
  onChange,
  validityFields
}) => {
  const [charCount, setCharCount] = useState(maxChars)

  useEffect(() => {
    setCharCount(maxChars - value.length)
  }, [value])

  return (
    <div>
      <textarea
        type={type}
        data-isInvalid={validityFields.includes(fieldName)}
        value={value}
        css={charCount < 0 ? errorBorderStyle : borderStyle}
        className='textboxStyle'
        onChange={onChange}
      />
      <div css={charCount < 0 ? charCountError : charCountStyle}>
        {charCount >= 0
          ? `${charCount} characters remaining`
          : `Character limit exceeded by ${-charCount}`}
      </div>
    </div>
  )
}

const getChannelSource = channel => {
  let channelSource = channelSourceList.find(
    channelSource => channelSource.key === channel
  )
  return (
    <img
      className='iconStyle'
      alt={channelSource.alt}
      src={channelSource.src}
    ></img>
  )
}

const AdDetails = ({
  showAdCampaignDetails,
  selectedChannels,
  campaignDetails,
  setCampaignDetails = () => {},
  validityFields = []
}) => {
  const { fbPages = [], linkedinPages = [] } = useSelector(
    ({ ads: { fbPages } = {} }) => ({
      fbPages
    })
  )
  const channelBudgetBreakdown = selectedChannels.filter(channel => channel)
  const [invalidUrl, setInvalidUrl] = useState(false)

  const onCheckboxClickHandler = event => {
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    const channelBudget = campaignDetails.budget / channelBudgetBreakdown.length
    channelBudgetBreakdown.forEach(channel => {
      prevCampaignDetails[`${channel}Budget`] = channelBudget
    })
    setCampaignDetails({
      ...prevCampaignDetails,
      spreadBudgetAcrossChannels: event.target.checked
    })
  }

  const handleOnChange = (key, value) => {
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    setCampaignDetails({ ...prevCampaignDetails, [key]: value })
  }

  const handleFbPageIdSelection = value => {
    const { id } = fbPages.find(({ name }) => name === value) || {}
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    setCampaignDetails({
      ...prevCampaignDetails,
      ...(selectedChannels.includes('facebook')
        ? { fb_page_id: id }
        : { fb_page_id: '' })
    })
  }

  const handleLinkedinPageIdSelection = value => {
    const { id } = linkedinPages.find(({ name }) => name === value) || {}
    const prevCampaignDetails = JSON.parse(JSON.stringify(campaignDetails))
    setCampaignDetails({
      ...prevCampaignDetails,
      ...(selectedChannels.includes('linkedin')
        ? { linkedin_page_id: id }
        : { linkedin_page_id: '' })
    })
  }

  return (
    <div css={content(showAdCampaignDetails)}>
      <div css={campaignContainer}>
        <div className='budgetContainer'>
          <label css={label}>Budget</label>
          <div css={inputWithCurrencyStyle}>
            <span className='currency'>$</span>
            <input
              css={inputStyle}
              className='inputText'
              type='number'
              value={campaignDetails.budget}
              onChange={event =>
                setCampaignDetails({
                  ...campaignDetails,
                  budget: event.target.value
                })
              }
            />
          </div>
        </div>
        {channelBudgetBreakdown.length > 0 && (
          <div css={spreadAcrossChannelOption}>
            <Checkbox
              checked={campaignDetails.spreadBudgetAcrossChannels}
              onChange={e => onCheckboxClickHandler(e)}
            />
            <label className='labelText'>Spread evenly across channels</label>
          </div>
        )}
        <div css={channelContainer}>
          {channelBudgetBreakdown.map(channelName => {
            const budgetName = `${channelName}Budget`
            return (
              <div css={channel} key={channelName}>
                {getChannelSource(channelName)}
                <label css={channelLabel}>
                  {channelName[0].toUpperCase() +
                    channelName.slice(1).toLowerCase()}
                </label>
                <div css={inputWithCurrencyStyle}>
                  <span className='currency'>$</span>
                  <input
                    css={inputStyle}
                    className='inputText'
                    value={campaignDetails[budgetName]}
                    onChange={({ target: { value } = {} }) =>
                      setCampaignDetails({
                        ...campaignDetails,
                        [budgetName]: value
                      })
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
        {selectedChannels.includes('facebook') && (
          <div css={pageIdContainer}>
            <label>Facebook Page Id</label>
            <Dropdown
              value={''}
              style={callToActionDropdownStyle}
              options={fbPages.map(({ name }) => name)}
              convertToTitleCase
              onOptionSelected={handleFbPageIdSelection}
            ></Dropdown>
          </div>
        )}
        {selectedChannels.includes('linkedin') && (
          <div css={pageIdContainer}>
            <label>Linkedin Page Id</label>
            <Dropdown
              value={''}
              style={callToActionDropdownStyle}
              options={linkedinPages.map(({ name }) => name)}
              convertToTitleCase
              onOptionSelected={handleLinkedinPageIdSelection}
            ></Dropdown>
          </div>
        )}
        <div css={dateContainer}>
          <div className='startDate'>
            <div>Start Date</div>
            <input
              css={inputStyle}
              type='date'
              value={campaignDetails.startDate}
              onChange={({ target: { value } = {} }) =>
                setCampaignDetails({
                  ...campaignDetails,
                  startDate: value
                })
              }
              min={
                new Date(new Date().setDate(new Date().getDate() + 1))
                  .toISOString()
                  .split('T')[0]
              }
            />
          </div>
          <div className='endDate'>
            <div>End Date</div>
            <input
              css={inputStyle}
              type='date'
              value={campaignDetails.endDate}
              onChange={({ target: { value } = {} }) =>
                setCampaignDetails({
                  ...campaignDetails,
                  endDate: value
                })
              }
              min={
                new Date(new Date().setDate(new Date().getDate() + 1))
                  .toISOString()
                  .split('T')[0]
              }
            />
          </div>
        </div>
      </div>

      <div css={adContainer}>
        <label className='requiredLabel'>* Required</label>
        <div css={textContainer}>
          <label>Text*</label>
          <CharacterLimitInput
            maxChars={50}
            type='text'
            fieldName='text'
            validityFields={validityFields}
            value={campaignDetails.text}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('text', value)
            }
          />
        </div>
        <div css={headlineContainer}>
          <label>Headline*</label>
          <CharacterLimitInput
            maxChars={50}
            type='text'
            fieldName='headline'
            validityFields={validityFields}
            value={campaignDetails.headline}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('headline', value)
            }
          />
        </div>
        <div css={newsfeedDescriptionContainer}>
          <label>Newsfeed Link Description*</label>
          <CharacterLimitTextArea
            maxChars={200}
            type='text'
            fieldName='newsFeedLinkDescription'
            validityFields={validityFields}
            value={campaignDetails.newsFeedLinkDescription}
            onChange={({ target: { value } = {} }) =>
              handleOnChange('newsFeedLinkDescription', value)
            }
          />
        </div>
        <div css={displayLinkContainer}>
          <label>Display Link</label>
          <div>
            <input
              className='textboxStyle'
              css={invalidUrl && errorBorderStyle}
              value={campaignDetails.displayLink}
              onChange={({ target: { value } = {} }) => {
                const isValidURL = validateURL(value)
                if (isValidURL || value === '') {
                  setInvalidUrl(false)
                } else {
                  setInvalidUrl(true)
                }
                handleOnChange('displayLink', value)
              }}
            />
            <div css={errorMessageStyle}>
              {invalidUrl ? 'Please enter valid url' : null}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div>
            <label>Objective</label>
            <div>
              <Dropdown
                value={
                  (campaignDetails.objective &&
                    campaignDetails.objective === 'OUTCOME_AWARENESS' &&
                    'REACH') ||
                  (campaignDetails.objective &&
                    campaignDetails.objective === 'OUTCOME_LEADS' &&
                    'LEAD_GENERATION') ||
                  ''
                }
                style={callToActionDropdownStyle}
                options={['REACH', 'LEAD_GENERATION']}
                convertToTitleCase
                onOptionSelected={value => {
                  if (value === 'REACH') {
                    handleOnChange('objective', 'OUTCOME_AWARENESS')
                  } else {
                    handleOnChange('objective', 'OUTCOME_LEADS')
                  }
                }}
              ></Dropdown>
            </div>
          </div>
          <div>
            <label>Call to Action</label>
            <div>
              <Dropdown
                value={campaignDetails.callToAction}
                style={callToActionDropdownStyle}
                convertToTitleCase
                options={CALL_TO_ACTION}
                onOptionSelected={value =>
                  handleOnChange('callToAction', value)
                }
              ></Dropdown>
            </div>
          </div>
        </div>
      </div>

      <div css={previewContainer}>
        <label>Preview</label>
        <div css={previewDropdownContainer}>
          <Dropdown
            value={'Facebook'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDropdownStyle}
          />
          <Dropdown
            value={'Single Image'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDropdownStyle}
          />
          <Dropdown
            value={'Newsfeed'}
            options={['Facebook', 'Instagram', 'Linkedin']}
            style={previewDesktopDropdownStyle}
          />
        </div>
        <div css={blank}></div>
      </div>
    </div>
  )
}

export default AdDetails
