import { createSlice } from '@reduxjs/toolkit'

const initialConnectionState = {
  connect: '',
  accounts: []
}

const channelIntegration = createSlice({
  name: 'channelIntegration',
  initialState: initialConnectionState,
  reducers: {
    updateConnectionAccounts (state, action) {
      state.connect = action.payload.connect
      state.accounts = action.payload.accounts
    }
  }
})

export const { updateConnectionAccounts } = channelIntegration.actions

export default channelIntegration.reducer
