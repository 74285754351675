/** @jsxImportSource @emotion/react */
import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import SegmentIcon from '../../../assets/images/dashboard/segmentIcon.png'
import AddIcon from '../../../assets/images/dashboard/plus.png'
import ConnectorIcon from '../../../assets/images/dashboard/connectorIcon.png'
import Audiences from '../../../assets/images/dashboard/audiences.png'
import Ad from '../../../assets/images/dashboard/ad.png'
import Alert from '../../../assets/images/dashboard/alert.png'
import Email from '../../../assets/images/dashboard/email.png'
import Form from '../../../assets/images/dashboard/form.png'
import Sms from '../../../assets/images/dashboard/sms.png'
import MailHeader from '../MailerEditor/MailHeader'
import { closeCampaign } from '../../../store/dashboard/mailerSlice'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../ui/Modal'
import {
  postEmailCampaignHandler,
  updateSavingMail,
  updateMailCampaignValidityObject
} from '../../../store/dashboard/mailerSlice'

import {
  main,
  container,
  buttonStyle,
  secondaryButtonStyle,
  secondaryButtonContainerStyle,
  addIconStyle,
  modalInnerContainer,
  validationTextStyle
} from './style'
import {
  updateModalState,
  updateRefCampaignType,
  updateAdsValidationModalResponse
} from '../../../store/dashboard/dashboardCommonSlice'
import {
  checkForValidCampaign,
  convertToTitleCase
} from '../../../utils/mailerUtils'

const SegmentHeader = ({
  stickyHeader,
  adsClicked,
  mailClicked,
  onSegmentClick = () => {},
  headerAudienceBtnClick = () => {},
  setCampaignCreationType = () => {},
  campaignCreationType,
  editingMailCampaignId,
  editingMailCampaignData,
  emailCampaignName: campaignName
}) => {
  const dispatch = useDispatch()
  const [mailPayload, setMailPayload] = useState(null)
  const { id: productId } = JSON.parse(localStorage.getItem('productInfo')) || {
    id: 2
  }
  const [showMailCampaignErrorModal, setShowMailCampaignErrorModal] =
    useState(null)
  const [mailValidityFields, setMailValidityFields] = useState({})
  const {
    modalState,
    refCampaignType,
    emails,
    emailTemplates,
    templateTree,
    mailValidityObject
  } = useSelector(
    ({
      dashboardCommon: { modalState, refCampaignType },
      mailer: { emails, emailTemplates, templateTree, mailValidityObject }
    }) => ({
      modalState,
      refCampaignType,
      emails,
      emailTemplates,
      templateTree,
      mailValidityObject
    })
  )

  const prepareMailPaylaod = () => {
    let isEditing = false
    if (editingMailCampaignData) {
      isEditing = true
    }
    let payload = {
      campaign_name: campaignName,
      product_id: productId,
      ...(isEditing ? { id: editingMailCampaignId } : {}),
      emails: []
    }
    Object.keys(emails).forEach(segmentId => {
      ;(emails[segmentId] || []).forEach(email => {
        const {
          id: mailId,
          emailName,
          emailType,
          subjectLine,
          additionalAudienceFilter,
          audienceData,
          sender,
          backupSender,
          testEmail,
          recurring,
          recurringRule,
          createdFromSegments,
          backupSenderEmail,
          customSenderEmail = '',
          sendDate = ''
        } = email || {}
        const { emailString } =
          (emailTemplates[segmentId] || []).find(
            ({ audienceId }) => audienceId == mailId
          ) || {}
        const emailLayoutTree = (templateTree[segmentId] || {})[mailId]
        if (
          payload.emails.findIndex(({ temp_id }) => temp_id === mailId) ===
            -1 &&
          Object.keys(email).length
        ) {
          payload['emails'] = [
            ...payload.emails,
            {
              email_name: emailName,
              email_type: emailType,
              subject_line: subjectLine,
              additional_audience_filter: additionalAudienceFilter,
              audience_ids: (audienceData || [])
                .filter(({ isSelected }) => isSelected)
                .map(({ id }) => id),
              sender,
              backup_sender: backupSender,
              test_email: testEmail,
              backup_sender_email: backupSenderEmail,
              recurring,
              recurring_rule: recurringRule,
              template_string: window.btoa(
                encodeURIComponent(emailString) || ''
              ),
              segment_ids: createdFromSegments,
              email_layout_tree: emailLayoutTree,
              custom_sender_email: customSenderEmail,
              send_date: sendDate,
              temp_id: mailId,
              ...(isEditing && /^\d+$/.test(mailId.toString())
                ? { id: mailId }
                : {})
            }
          ]
        }
      })
    })
    return payload
  }

  const saveMailCampaign = saveType => {
    const payload = prepareMailPaylaod()
    if (saveType === 'save_draft') {
      payload['is_draft'] = 'true'
    }

    if (saveType === 'save_draft') {
      dispatch(postEmailCampaignHandler(payload))
      dispatch(updateModalState(null))
      dispatch(updateRefCampaignType(null))
      setMailPayload(null)
      return
    }

    const validityObject = checkForValidCampaign(emails, saveType)
    dispatch(updateMailCampaignValidityObject(validityObject))
    const validSegments = Object.keys(validityObject).filter(
      key => Object.keys(validityObject[key]).length > 0
    )

    const isValidCampaign =
      validSegments.length &&
      validSegments.every(segment => {
        return Object.values(validityObject[segment]).every(field =>
          Object.values(field).every(values => values)
        )
      })
    if (isValidCampaign) {
      setMailPayload({ saveType, payload })
      dispatch(updateModalState('save_mail'))
    } else {
      setShowMailCampaignErrorModal({ saveType, isValidCampaign })
    }
  }

  const getModalUi = () => {
    if (
      modalState === 'email_cancel' ||
      modalState === 'navigate_to_ads' ||
      modalState === 'navigate_to_email'
    ) {
      return (
        <div css={modalInnerContainer}>
          <p className='heading'>Save before exiting?</p>
          <p className='sub-heading'>
            Your updates will be deleted from the system
          </p>
          <div className='buttonContainer'>
            <button
              className='edit-button'
              onClick={() => {
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(campaignCreationType))
              }}
            >
              Keep Editing
            </button>
            <button
              onClick={() => {
                saveMailCampaign('save_draft')
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(null))
              }}
            >
              Save Draft
            </button>
            <button
              onClick={() => {
                if (
                  modalState === 'navigate_to_ads' ||
                  modalState === 'navigate_to_email'
                ) {
                  setCampaignCreationType(refCampaignType)
                }
                if (modalState === 'email_cancel') {
                  dispatch(updateRefCampaignType(null))
                }
                if (modalState === 'email_cancel') {
                  setCampaignCreationType('close')
                  dispatch(closeCampaign(true))
                  setTimeout(() => {
                    dispatch(closeCampaign(false))
                  }, 500)
                }
                dispatch(updateModalState(null))
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )
    }

    if (modalState === 'ads_cancel') {
      return (
        <div css={modalInnerContainer}>
          <p className='heading'>Save before exiting?</p>
          <p className='sub-heading'>
            Your updates will be deleted from the system
          </p>
          <div className='buttonContainer'>
            <button
              className='edit-button'
              onClick={() => {
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(campaignCreationType))
              }}
            >
              Keep Editing
            </button>
            <button
              onClick={() => {
                dispatch(updateAdsValidationModalResponse('save_draft'))
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(null))
              }}
            >
              Save Draft
            </button>
            <button
              onClick={() => {
                dispatch(updateModalState(null))
                dispatch(updateAdsValidationModalResponse('Delete'))
              }}
            >
              Delete
            </button>
          </div>
        </div>
      )
    }

    if (modalState === 'save_mail') {
      return (
        <div css={modalInnerContainer}>
          <p className='heading'>Ready to launch?!!</p>
          <p className='sub-heading'>
            Your campaign will begin sending within minutes
          </p>
          <div className='buttonContainer'>
            <button
              className='edit-button'
              onClick={() => {
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(campaignCreationType))
              }}
            >
              Keep Editing
            </button>
            <button
              onClick={() => {
                saveMailCampaign('save_draft')
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(null))
              }}
            >
              Save Draft
            </button>
            <button
              className='go-live'
              onClick={() => {
                const { saveType, payload } = mailPayload || {}
                if (saveType !== 'save_draft') {
                  dispatch(updateSavingMail(true))
                }
                dispatch(postEmailCampaignHandler(payload))
                dispatch(updateModalState(null))
                dispatch(updateRefCampaignType(null))
                setMailPayload(null)
              }}
            >
              Go Live
            </button>
          </div>
        </div>
      )
    }
  }

  const checkForCampaignChange = campaignType => {
    if (!refCampaignType) {
      setCampaignCreationType(campaignType)
      dispatch(updateRefCampaignType(campaignType))

      return
    }
    if (campaignType !== refCampaignType) {
      dispatch(
        updateModalState(
          refCampaignType == 'mail' ? 'navigate_to_ads' : 'navigate_to_email'
        )
      )
      dispatch(updateRefCampaignType(campaignType))
    }
    if (campaignType === refCampaignType) {
      setCampaignCreationType(null)
      dispatch(updateRefCampaignType(null))
    }
  }

  const headerButtons = useMemo(
    () => [
      {
        id: 1,
        name: 'Audience',
        icon: Audiences,
        callback: headerAudienceBtnClick
      },
      {
        id: 2,
        name: 'Ad',
        icon: Ad,
        callback: () => checkForCampaignChange('ads')
      },
      {
        id: 3,
        name: 'Email',
        icon: Email,
        callback: () => checkForCampaignChange('mail')
      },
      {
        id: 4,
        name: 'Alert',
        icon: Alert
      }
      // {
      //   id: 5,
      //   name: 'Form',
      //   icon: Form
      // },
      // {
      //   id: 6,
      //   name: 'SMS',
      //   icon: Sms
      // }
    ],
    [headerAudienceBtnClick, setCampaignCreationType]
  )

  const getMailValidityFields = () => {
    let validityObj = {}
    const segments = Object.keys(mailValidityObject)
    segments.forEach(segment => {
      const mailIds = Object.keys(mailValidityObject[segment] || {})
      if (mailIds && mailIds.length) {
        mailIds.forEach(mailId => {
          const emailObject = mailValidityObject[segment][mailId]
          if (Object.values(emailObject).every(value => value)) {
            return
          }
          if (Object.values(emailObject).length) {
            validityObj[mailId] = Object.keys(emailObject).filter(
              key => !emailObject[key]
            )
          }
        })
      }
    })

    return validityObj
  }

  useEffect(() => {
    if (showMailCampaignErrorModal) {
      const validityFields = getMailValidityFields()
      setMailValidityFields(validityFields)
    }
  }, [showMailCampaignErrorModal])
  return (
    <>
      <div css={main(stickyHeader)}>
        <div css={container}>
          <span css={addIconStyle}>
            <img src={AddIcon} alt='plus-icon' />
          </span>
          {headerButtons.map(({ id, name, icon, callback }, index) => (
            <button
              key={index}
              css={buttonStyle(id)}
              onClick={callback ? callback : () => {}}
            >
              <span className='icon'>
                <img src={icon} alt='icon' />
              </span>
              <span className='label'>{name}</span>
            </button>
          ))}
          <div css={secondaryButtonContainerStyle}>
            <button css={secondaryButtonStyle} onClick={onSegmentClick}>
              <span className='icon'>
                <img src={SegmentIcon} alt='icon' />
              </span>
              <span className='label'>Segment</span>
            </button>
            <button css={secondaryButtonStyle}>
              <span className='icon'>
                <img src={ConnectorIcon} alt='icon' />
              </span>
              <span className='label'>
                <Link
                  to='/cdp/connectors'
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  Connector
                </Link>
              </span>
            </button>
          </div>
        </div>
        {mailClicked && (
          <MailHeader
            onCancelHandler={() => {
              dispatch(updateModalState('email_cancel'))
            }}
            saveMailCampaign={saveType => saveMailCampaign(saveType)}
          />
        )}
      </div>
      {modalState && <Modal showCross={false}>{getModalUi()}</Modal>}
      {showMailCampaignErrorModal &&
        !showMailCampaignErrorModal?.isValidCampaign && (
          <Modal
            showCross={false}
            onClose={() => setShowMailCampaignErrorModal(null)}
          >
            <div css={modalInnerContainer}>
              <p style={{ textAlign: 'left' }}>
                Please edit the following required fields for highlighted emails
                to save campaign:
              </p>
              {Object.keys(mailValidityFields).map((key, index) => {
                return (
                  <div css={validationTextStyle} key={`${key}-${index}`}>
                    <div className='emailName'>{key}</div>
                    <div className='fields'>
                      {(mailValidityFields[key] || []).map(item => (
                        <span>{convertToTitleCase(item)}</span>
                      ))}
                    </div>
                  </div>
                )
              })}
              <div className='buttonContainer'>
                <button
                  onClick={() => {
                    setShowMailCampaignErrorModal(false)
                    saveMailCampaign('save_draft')
                    dispatch(updateRefCampaignType(null))
                  }}
                >
                  Save Draft
                </button>
                <button onClick={() => setShowMailCampaignErrorModal(null)}>
                  Keep Editing
                </button>
              </div>
            </div>
          </Modal>
        )}
    </>
  )
}

export default SegmentHeader
