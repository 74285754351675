/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import axiosInstance from '../../../axiosConfig'
import { useDispatch } from 'react-redux'
import { loadInitialData } from '../../../store/onboardingDashboard'

import {
  container,
  textContainer,
  actionButtonContainer,
  actionButton,
  messageContainer,
  loginMessageStyle,
  imageWrapper
} from './style'

import salesforceIcon from '../../../assets/images/onboardingDashboard/crmIcons/salesforceIcon.png'
import sapIcon from '../../../assets/images/onboardingDashboard/crmIcons/sapIcon.png'
import oracleIcon from '../../../assets/images/onboardingDashboard/crmIcons/oracleIcon.png'
import microsoftIcon from '../../../assets/images/onboardingDashboard/crmIcons/microsoftIcon.png'
import adobeIcon from '../../../assets/images/onboardingDashboard/crmIcons/adobeIcon.png'
import zohoIcon from '../../../assets/images/onboardingDashboard/crmIcons/zohoIcon.png'
import hubspotIcon from '../../../assets/images/onboardingDashboard/crmIcons/hubspotIcon.png'
import freshdeskIcon from '../../../assets/images/onboardingDashboard/crmIcons/freshdeskIcon.png'
import zendeskIcon from '../../../assets/images/onboardingDashboard/crmIcons/zendeskIcon.png'
import keapIcon from '../../../assets/images/onboardingDashboard/crmIcons/keapIcon.png'
import sugarcrmIcon from '../../../assets/images/onboardingDashboard/crmIcons/sugarcrmIcon.png'
import pipedriveIcon from '../../../assets/images/onboardingDashboard/crmIcons/pipedriveIcon.png'

const crmOptionsData = [
  {
    id: 1,
    name: 'Salesforce',
    source: salesforceIcon
  },
  {
    id: 2,
    name: 'SAP',
    source: sapIcon
  },
  {
    id: 3,
    name: 'Oracle',
    source: oracleIcon
  },
  {
    id: 4,
    name: 'Microsoft',
    source: microsoftIcon
  },
  {
    id: 5,
    name: 'Adobe',
    source: adobeIcon
  },
  {
    id: 6,
    name: 'Zoho',
    source: zohoIcon
  },
  {
    id: 7,
    name: 'HubSoft',
    source: hubspotIcon
  },
  {
    id: 8,
    name: 'FreshDesk',
    source: freshdeskIcon
  },
  {
    id: 9,
    name: 'Zendesk',
    source: zendeskIcon
  },
  {
    id: 10,
    name: 'Keap',
    source: keapIcon
  },
  {
    id: 11,
    name: 'SugarCRM',
    source: sugarcrmIcon
  },
  {
    id: 12,
    name: 'Pipedrive',
    source: pipedriveIcon
  }
]

const mockFormData = {
  user_name: 'rmallelli@getneyo.com',
  password: '5crh4bku@SB',
  domain_url: 'https://neyo2-dev-ed.my.salesforce.com/services/oauth2/token',
  client_id:
    '3MVG9wt4IL4O5wvL_eBnv7pdX4KnGIrM.2UCEiZDh79ZdtL5LoZaoaEd3Xku93KmqT8VH18MmgUaImevNid8z',
  client_secret:
    'BFD9D0C341AC16CE1A8959E1E5FB9BC597FA061032B0AB08A6A50031285D028C'
}

const CrmIntegrationModalForm = ({
  setModalViewFlag,
  onCrmIntegrationSuccess,
  currentSelectedCrm
}) => {
  const { source } =
    crmOptionsData.find(({ name }) => name === currentSelectedCrm) || {}
  const [formData, setFormData] = useState(mockFormData)
  const [error, setError] = useState('')
  const [message, setMessage] = useState(
    'Failed to authenticate with salesforce account, Please check your credentials.'
  )
  const [showSuccessOption, setShowSuccessOption] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleOnChange = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const handleLogIn = async () => {
    const dataToSend = { salesforce: formData }
    setLoading(true)
    try {
      const { data, status } = await axiosInstance.post(
        '/onboarding/salesforce_login',
        dataToSend
      )
      if (status === 200) {
        setLoading(false)
        setMessage('Success!')
        dispatch(loadInitialData())
        setShowSuccessOption(true)
        onCrmIntegrationSuccess()
      } else {
        setLoading(false)
        setError('Credentials Invalid: Try Again')
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      setLoading(false)
      setError('Server error: Try Again')
    }
  }

  return (
    <div css={container(showSuccessOption, error)}>
      {showSuccessOption && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div css={imageWrapper}>
              <img src={source} />
            </div>
            <div css={loginMessageStyle}>Log in to your account</div>
          </div>
          <div css={messageContainer(showSuccessOption, error)}>
            <p>{`${message}`}</p>
          </div>
          <div css={actionButtonContainer}>
            <button
              className='logInButton'
              style={{
                width: 164,
                color: '#333',
                border: '1px solid #CCCCCC',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 0 NaNpx 2px rgba(102,153,204,0.4)'
              }}
              css={actionButton(loading)}
              onClick={() => setModalViewFlag(false)}
            >
              Let's keep going
            </button>
          </div>
        </div>
      )}
      {error && (
        <>
          <div css={messageContainer(showSuccessOption, error)}>
            <p>{`${message}`}</p>
          </div>
          <div css={actionButtonContainer}>
            <button
              className='logInButton'
              css={actionButton(loading)}
              onClick={() => setError('')}
            >
              Try Again
            </button>
          </div>
        </>
      )}
      {!showSuccessOption && !error && (
        <>
          <label className='requiredLabel'>* Required</label>
          <div css={textContainer}>
            <label>User Name*</label>
            <div>
              <input
                className='textboxStyle'
                onChange={({ target: { value } = {} }) =>
                  handleOnChange('user_name', value)
                }
                value={formData.user_name}
              ></input>
            </div>
          </div>
          <div css={textContainer}>
            <label>Password*</label>
            <div>
              <input
                className='textboxStyle'
                onChange={({ target: { value } = {} }) =>
                  handleOnChange('password', value)
                }
                value={formData.password}
              ></input>
            </div>
          </div>
          <div css={textContainer}>
            <label>Domain URL*</label>
            <div>
              <input
                className='textboxStyle'
                onChange={({ target: { value } = {} }) =>
                  handleOnChange('domain_url', value)
                }
                value={formData.domain_url}
              ></input>
            </div>
          </div>
          <div css={textContainer}>
            <label>Client Id*</label>
            <div>
              <input
                className='textboxStyle'
                onChange={({ target: { value } = {} }) =>
                  handleOnChange('client_id', value)
                }
                value={formData.client_id}
              ></input>
            </div>
          </div>
          <div css={textContainer}>
            <label>Client Secret*</label>
            <div>
              <input
                className='textboxStyle'
                onChange={({ target: { value } = {} }) =>
                  handleOnChange('client_secret', value)
                }
                value={formData.client_secret}
              ></input>
            </div>
          </div>
          <div css={actionButtonContainer}>
            <button
              css={actionButton(loading)}
              onClick={() => setModalViewFlag(false)}
            >
              Back
            </button>
            <button
              className='logInButton connect-button'
              css={actionButton(loading)}
              onClick={handleLogIn}
            >
              Connect
              {loading && <div className='loader' />}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default CrmIntegrationModalForm
