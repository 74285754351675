import { createSlice } from '@reduxjs/toolkit'

import {
  mapDataForDatasource,
  mapDataFromDataSource,
  mapDataForDatasourceForCopyOperation
} from '../../mapper/dashboard/ads'
import axiosInstance from '../../axiosConfig'

const initialAds = {
  campaignCollection: [],
  showLoader: false,
  adCounter: 1,
  fbPages: [],
  linkedinPages: []
}

const adsSlice = createSlice({
  name: 'ads',
  initialState: initialAds,
  reducers: {
    getInitialData (state, action) {
      state.campaignCollection = action.payload
    },
    updateShowLoader (state, actions) {
      state.showLoader = actions.payload
    },
    updateAdCounter (state, actions) {
      state.adCounter = actions.payload
    },
    facebookPages (state, actions) {
      state.fbPages = actions.payload
    },
    linkedinPages (state, actions) {
      state.linkedinPages = actions.payload
    }
  }
})

export const getAdsData = productId => {
  return async dispatch => {
    try {
      const { data, status } = await axiosInstance.get(
        `/ads/campaign_ads?product_id=${productId}`
      )
      if (status === 200) {
        const campaignData = mapDataFromDataSource(data, productId)
        dispatch(adsSlice.actions.getInitialData(campaignData))
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const createNewAdCampaign = (
  productId,
  adCampaignData,
  campaignDetails
) => {
  return async dispatch => {
    try {
      dispatch(adsSlice.actions.updateShowLoader(true))
      const dataToSend = mapDataForDatasource(
        adCampaignData,
        campaignDetails,
        productId
      )
      const { data, status } = await axiosInstance.post(
        '/ads/universal',
        dataToSend
      )
      if (status === 200) {
        dispatch(adsSlice.actions.updateShowLoader(false))
        dispatch(getAdsData(productId))
      } else {
        dispatch(adsSlice.actions.updateShowLoader(false))
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      dispatch(adsSlice.actions.updateShowLoader(false))
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const copyAdCampaign = (productId, adCampaignData, campaignDetails) => {
  return async dispatch => {
    try {
      const dataToSend = mapDataForDatasourceForCopyOperation(
        adCampaignData,
        campaignDetails,
        productId
      )
      const { data, status } = await axiosInstance.post(
        '/ads/universal',
        dataToSend
      )
      console.log('copyAdCampaign response', data)
      if (status === 200) {
        dispatch(getAdsData(productId))
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const deleteAdCampaign = (productId, campaignId) => {
  return async dispatch => {
    try {
      const { status } = await axiosInstance.delete(`/campaigns/${campaignId}`)
      if (status === 200) {
        dispatch(getAdsData(productId))
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

export const uploadImageToFacebookBE = formData => {
  return async dispatch => {
    try {
      const { status, data: { url } = {} } = await axiosInstance.post(
        '/fbs/ad_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      if (status === 200) {
        return { url }
      }
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const uploadVideoToFacebookBE = formData => {
  return async dispatch => {
    try {
      const { status, data: { url } = {} } = await axiosInstance.post(
        '/fbs/ad_video',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      if (status === 200) {
        return { url }
      }
    } catch (err) {
      throw new Error(err)
    }
  }
}

let BASE_URL = 'https://neyoaibucket.s3.amazonaws.com'

export const uploadVideoFileToS3 = ({ file, key, channel }) => {
  return async (dispatch, getState) => {
    try {
      const fetchUrl = `${BASE_URL}/videos/${channel}/${key}`

      const response = await fetch(fetchUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'video/mp4' // Set to the MIME type of the video, e.g., video/mp4
        },
        body: file
      })

      if (response.status === 200) {
        // If the response has a JSON body (check your server documentation for this)
        try {
          const data = await response.json() // If it's JSON, parse it
          return { url: data.url }
        } catch (error) {
          // Handle cases where there is no JSON body
          return { url: fetchUrl }
        }
      } else {
        console.error(`Upload failed with status: ${response.status}`)
        throw new Error(`Upload failed with status: ${response.status}`)
      }
    } catch (err) {
      console.error('Error uploading video file:', err)
      throw new Error(err)
    }
  }
}

export const uploadFileToS3FB = (file, key) => {
  return async (dispatch, getState) => {
    try {
      let fetchurl = `${BASE_URL}/facebookads/${key}`
      const { url, status } = await fetch(fetchurl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'inline'
        },
        body: file
      })
      if (status === 200) {
        return { url }
      }
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getFacebookPageIds = () => {
  return async (dispatch, getState) => {
    try {
      const { data: { pages } = {} } = await axiosInstance.get('/fbs/pages')
      dispatch(adsSlice.actions.facebookPages(pages))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getLinkedinPageIds = () => {
  return async (dispatch, getState) => {
    try {
      const { data: { pages } = {} } = await axiosInstance.get(
        '/linked_in/pages'
      )
      dispatch(adsSlice.actions.linkedinPages(pages))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const queueActions = adsSlice.actions

export const { updateAdCounter } = queueActions

export default adsSlice.reducer
