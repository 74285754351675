import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import SignIn from '../SignIn/SignIn'
import SignUp from '../SignUp/SignUp'
import CdpPage from '../CdpPage/CdpPage'
import Dashboard from '../Dashboard/Dashboard'
import OnboardingDashboard from '../OnboardingDashboard'
import OAuthCallback from '../OauthCallback'

import { authActions } from '../../store/authSlice'

const getTokenFromLocalStorage = () => {
  return localStorage.getItem('token')
}

const getOnboardedDetailsFromLocalStorage = () => {
  return localStorage.getItem('onboarded')
}

const getUserRoleFromLocalStorage = () => {
  return localStorage.getItem('userRole')
}

const LandingPage = () => {
  const dispatch = useDispatch()
  const token =
    useSelector(state => state.auth.token) || getTokenFromLocalStorage()
  const onboarded =
    useSelector(state => state.auth.onboarded) ||
    getOnboardedDetailsFromLocalStorage()

  useEffect(() => {
    const token = JSON.parse(getTokenFromLocalStorage())
    const userRole = JSON.parse(getUserRoleFromLocalStorage())
    if (token) {
      dispatch(
        authActions.login({
          token,
          onboarded: getOnboardedDetailsFromLocalStorage(),
          user_role: userRole
        })
      )
    } else {
      dispatch(authActions.logout())
    }
  }, [dispatch])

  return (
    <BrowserRouter>
      <Routes>
        {!token ? (
          <>
            <Route path='/signup' element={<SignUp />} />
            <Route path='/' element={<SignIn />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </>
        ) : (
          <>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route
              path='/'
              element={
                onboarded === 'false' || !onboarded ? (
                  <OnboardingDashboard />
                ) : (
                  <Dashboard />
                )
              }
            />
            <Route path='/cdp' element={<CdpPage onboarded={onboarded} />} />
            <Route
              path='/cdp/:pageId'
              element={<CdpPage onboarded={onboarded} />}
            />
            <Route
              path='/onboarding-dashboard'
              element={<OnboardingDashboard />}
            />
            <Route path='/oAuthCallback' element={<OAuthCallback />} />
            <Route path='*' element={<Navigate to='/' replace />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default LandingPage
