import { createSlice } from '@reduxjs/toolkit'
import { showToast } from './toast/toastSlice'

import axiosInstance from '../axiosConfig'

const initialAuthState = {
  token: '',
  isLoggedIn: false,
  userRole: null,
  message: null
}

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login (state, action) {
      state.isLoggedIn = true
      if (Object.hasOwn(action.payload, 'onboarded'))
        state.onboarded = action.payload.onboarded
      if (Object.hasOwn(action.payload, 'token'))
        state.token = action.payload.token
      if (action.payload.hasOwnProperty('user_role')) {
        state.userRole = action.payload.user_role
      }
    },
    logout (state) {
      state.isLoggedIn = false
      state.token = ''
      state.onboarded = ''
      localStorage.removeItem('token')
      localStorage.removeItem('onboarded')
      localStorage.removeItem('userRole')
      localStorage.removeItem('productInfo')
    },
    updateMessage (state, actions) {
      state.message = actions.payload
    }
  }
})

export const loginHandler = ({ username, password }) => {
  return async dispatch => {
    const {
      data: { data, status: { code } = {}, product_id: productId } = {},
      headers: { authorization } = {}
    } = await axiosInstance.post('/login', {
      user: {
        email: username,
        password
      }
    })
    if (code === 200 && authorization) {
      dispatch(authSlice.actions.login({ ...data, token: authorization }))
      localStorage.setItem('token', JSON.stringify(authorization))
      localStorage.setItem('onboarded', JSON.stringify(data.onboarded))
      localStorage.setItem('userRole', JSON.stringify(data.user_role))
      localStorage.setItem('productInfo', JSON.stringify({ id: productId }))
    } else {
      const { error } = data
      dispatch(showToast({ message: error, type: 'error', isVisible: true }))
    }
  }
}

export const signUpHandler = signUpInfo => {
  return async dispatch => {
    const {
      data: { data, status: { code } = {} } = {},
      headers: { authorization } = {}
    } = await axiosInstance.post('/signup', {
      user: signUpInfo
    })
    if (code === 200 && authorization) {
      dispatch(authSlice.actions.login({ ...data, token: authorization }))
      localStorage.setItem('token', JSON.stringify(authorization))
      localStorage.setItem('userRole', JSON.stringify(data.user_role))
    } else {
      throw new Error('Invalid credentials')
    }
  }
}

export const clearDbHandler = () => {
  return async dispatch => {
    const { data, status } = await axiosInstance.get('/onboarding/clear_db')
    if (status === 200) {
      dispatch(authSlice.actions.updateMessage(data.message))
      setTimeout(() => {
        dispatch(authSlice.actions.updateMessage(null))
      }, [1000])
    }
  }
}

export const onboardFlagHandler = onboardFlag => {
  return async dispatch => {
    const { data, status } = await axiosInstance.post('/users/onboarded')
    if (status === 200) {
      dispatch(authSlice.actions.login({ onboarded: onboardFlag }))
      localStorage.setItem('onboarded', onboardFlag)
    } else {
      throw new Error('Invalid credentials')
    }
  }
}

export const authActions = authSlice.actions

export default authSlice.reducer
