/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useElementOnScreen from '../../customHooks/useObserveScreen'

import Header from './Header'
import BlankSlotSection from './BlankSlotSection'
import WelcomeSection from './WelcomeSection'
import CrmSection from './CrmSection'
import SocialAndAnalyticsSection from './SocialAndAnalyticsSection'
import BusinessTypeSection from './BusinessTypeSection'
import SegmentSection from './SegmentSection'
import TakeToDashboard from './TakeToDashboard'
import Footer from './Footer'
import { onboardFlagHandler, authActions } from '../../store/authSlice'
import { dashboardContainer } from './styles'

const OnboardingDashboard = () => {
  const dispatch = useDispatch()
  const [selectedSection, setSelectedSection] = useState('welcome')
  const [businessType, setBusinessType] = useState('')
  const [socialAccounts, setSocialAccounts] = useState(null)

  const syncReportData = useSelector(state => state.onboarding.syncReportData)

  const onboardHandler = () => {
    window.scrollTo(0, 0)
    dispatch(onboardFlagHandler(true))
  }

  const logOutHandler = () => {
    dispatch(authActions.logout())
  }

  const [welcomeRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: '-250px 0px -250px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )

  const [crmRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: '-230px 0px -100px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )
  const [socialAndAnalyticsRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: '-160px 0px -100px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )

  const [businessTypeRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: '-250px 0px -250px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )
  const [segmentRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: businessType
        ? '-70px 0px -100px 0px'
        : '-300px 0px -300px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )
  const [takeToDashboardRef] = useElementOnScreen(
    selectedSection,
    {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.9
    },
    setSelectedSection
  )

  const scrollHandler = sectionName => {
    const scrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    }
    switch (sectionName) {
      case 'crm': {
        crmRef.current.scrollIntoView(scrollIntoViewOptions)
        break
      }
      case 'socialAndAnalytics': {
        socialAndAnalyticsRef.current.scrollIntoView(scrollIntoViewOptions)
        break
      }
      case 'businessType': {
        businessTypeRef.current.scrollIntoView(scrollIntoViewOptions)
        break
      }
      case 'segment': {
        segmentRef.current.scrollIntoView(scrollIntoViewOptions)
        break
      }
      case 'takeToDashboard': {
        takeToDashboardRef.current.scrollIntoView({
          ...scrollIntoViewOptions,
          block: 'end'
        })
        break
      }
      default:
        break
    }
  }

  useEffect(() => {
    const handleAuthMessage = event => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (event.data.success) {
        const { accounts, connect } = event.data
        setSocialAccounts({ connect, accounts })
      }
    }
    window.addEventListener('message', handleAuthMessage)

    return () => {
      window.removeEventListener('message', handleAuthMessage)
    }
  }, [])

  return (
    <>
      <div css={dashboardContainer}>
        <Header logOutHandler={logOutHandler} />
        <BlankSlotSection sectionTopTo={'welcome'} />
        <WelcomeSection
          selectedSection={selectedSection}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          ref={welcomeRef}
        />
        <BlankSlotSection sectionTopTo={'crm'} />
        <CrmSection
          selectedSection={selectedSection}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          ref={crmRef}
        />
        <BlankSlotSection sectionTopTo={'socialAndAnalytics'} />
        <SocialAndAnalyticsSection
          selectedSection={selectedSection}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          ref={socialAndAnalyticsRef}
          socialAccounts={socialAccounts}
        />
        <BlankSlotSection sectionTopTo={'businessType'} />
        <BusinessTypeSection
          businessType={businessType}
          selectedSection={selectedSection}
          setBusinessType={setBusinessType}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          ref={businessTypeRef}
        />
        <BlankSlotSection sectionTopTo={'segment'} />
        <SegmentSection
          businessType={businessType}
          selectedSection={selectedSection}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          ref={segmentRef}
        />
        <BlankSlotSection sectionTopTo={'takeToDashboard'} />
        <TakeToDashboard
          selectedSection={selectedSection}
          syncReportData={syncReportData}
          scrollHandler={scrollHandler}
          setSelectedSection={setSelectedSection}
          onboardHandler={onboardHandler}
          ref={takeToDashboardRef}
        />
      </div>
      <Footer />
    </>
  )
}

export default OnboardingDashboard
