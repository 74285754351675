import { configureStore } from '@reduxjs/toolkit'

import authReducer from './authSlice'
import unifiedIdReducer from './cdp/unifiedIdSlice'
import importQueueReducer from './cdp/importQueueSlice'
import segmentsReducer from './dashboard/segmentsSlice'
import connectorReducer from './cdp/connectorsSlice'
import audiencesReducer from './dashboard/audienceSlice'
import dndReducer from './dashboard/dndSlice'
import mailerReducer from './dashboard/mailerSlice'
import onboardingReducer from './onboardingDashboard'
import recordsReducer from './cdp/recordsSlice'
import adsReducer from './dashboard/adsSlice'
import topfoldReducer from './dashboard/topfoldSlice'
import dashboardCommonReducer from './dashboard/dashboardCommonSlice'
import toastReducer from './toast/toastSlice'
import channelIntegrationReducer from './channelIntegrationSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    unifiedId: unifiedIdReducer,
    importQueue: importQueueReducer,
    segments: segmentsReducer,
    connectors: connectorReducer,
    audiences: audiencesReducer,
    dragAndDrop: dndReducer,
    mailer: mailerReducer,
    onboarding: onboardingReducer,
    records: recordsReducer,
    ads: adsReducer,
    topfold: topfoldReducer,
    dashboardCommon: dashboardCommonReducer,
    toast: toastReducer,
    channelIntegration: channelIntegrationReducer
  }
})

export default store
