import { css, keyframes } from '@emotion/react'

const bouncedelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`

export const container = css`
  position: relative;
  flex: 1;
  flex-shrink: 0;
`

export const copyActionIconContainer = copyButtonClicked => css`
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  right: 0px;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.88);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  ${copyButtonClicked && 'display: flex;'}
`

export const setWidthOfForm = (cardLength = 1) => css`
  width: ${cardLength * 231.4 + (cardLength - 1) * 33}px;
  min-width: ${cardLength * 231.4 + (cardLength - 1) * 33}px;
  @media (min-width: 1441px) {
    width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
    min-width: ${cardLength * 231.4 + (cardLength - 1) * 47}px;
  }
`

export const header = (
  expandedView,
  audienceIconClicked,
  rightMinimizeHoverFlag,
  leftMinimizeHoverFlag,
  cardLength,
  isValid
) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    height: 86px;
    width: 231.4px;
    min-width: 231.4px;
    margin: 10px auto;
    margin-bottom: 0px;
    border: 0.8px solid #CCCCCC;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    position: relative;
    box-shadow: 0 2px 6px -2px #0099FF;
    transition: all 0.4s ease-out;
    position: relative;
    .toggleHeight {
        height: 0px;
        width: 0px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
        transition: all 0.4s ease-out;
    }
    @media (min-width: 1441px) {
      margin: 12px 24px;
      margin-bottom: 0;
    }
    ${cardLength > 1 && !rightMinimizeHoverFlag && 'justify-content: center;'}
    ${
      expandedView &&
      'border-bottom: 0.8px solid #FFFFFF; border-radius: 4px 4px 0px 0px;'
    }
    ${expandedView && '.toggleHeight { height: 24px; width: 24px; }'}
    &:hover {
        ${!expandedView && '.toggleHeight { height: 24px; width: 24px; }'}
    }
    ${
      audienceIconClicked &&
      'border-bottom: 0.8px solid #FFFFFF; border-radius: 4px 4px 0px 0px; box-shadow: 0 2px 6px -2px #FFFFFF;'
    }
    .channelMediaContainer {
        display: flex;
        justify-content: center;
        ${rightMinimizeHoverFlag && cardLength > 2 && 'flex: 2;'}
    }
    ${setWidthOfForm(cardLength)}
    ${
      rightMinimizeHoverFlag &&
      cardLength > 1 &&
      'justify-content: space-between; padding-right: 0px;'
    }
    ${
      leftMinimizeHoverFlag &&
      cardLength > 1 &&
      'justify-content: space-between; padding-left: 0px;'
    }
      ${
        !isValid &&
        'border: 2px solid #ff00008c; box-shadow: 0 2px 6px -2px red;'
      }
`

export const minimizeFromLeft = leftMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${
    leftMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-left: 0px; background-color: rgba(229, 229, 229, 1);'
  }
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const minimizeFromRight = rightMinimizeHoverFlag => css`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  height: 86px;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  ${
    rightMinimizeHoverFlag &&
    'width: 231.4px; min-width: 231.4px; border: 4px dashed #cccccc; border-right: 0px; background-color: rgba(229, 229, 229, 1);'
  }
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
`

export const rightExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(100%, 0);
  right: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'right: -28px;'}
`

export const leftExtensionContainer = (cardLength = 1) => css`
  display: flex;
  position: absolute;
  transform: translate(-100%, 0);
  left: -14px;
  justify-content: flex-end;
  ${cardLength > 1 && 'left: -28px;'}
`

export const extensionButton = (cardLength = 1) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  height: 88px;
  width: 14px;
  overflow: hidden;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #18a3e2;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  label {
    color: #ffffff;
    font-family: BentonSans;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
  }
  &.leftExtension {
    position: absolute;
    left: -14px;
    ${cardLength > 1 && 'left: -28px;'}
  }
  &.leftMinimize {
    position: absolute;
    left: -14px;
  }
  &.rightExtension {
    position: absolute;
    right: -14px;
    ${cardLength > 1 && 'right: -28px;'}
  }
  &.rightMinimize {
    position: absolute;
    right: -14px;
  }
`

export const rightExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${
    extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
  }
  ${
    extensionHoverFlag &&
    cardLength === 1 &&
    'width: 250.4px; min-width: 250.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-left: 0px;'
  }
`

export const leftExtensionCard = (extensionHoverFlag, cardLength) => css`
  box-sizing: border-box;
  z-index: 1;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  margin-bottom: 0px;
  position: relative;
  border-radius: 4px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: rgba(229, 229, 229, 1);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  cursor: pointer;
  transition: all 0.5s ease-out;
  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
    font-weight: 900;
  }
  ${
    extensionHoverFlag &&
    'width: 237.4px; min-width: 237.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
  }
  ${
    extensionHoverFlag &&
    cardLength === 1 &&
    'width: 251.4px; min-width: 251.4px;padding: 20px 24px 20px 21px;border: 4px dashed #cccccc;border-right: 0px;'
  }
`

export const channelContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  box-sizing: border-box;
  height: 68px;
  width: 68px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  margin-right: 8px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
`

export const audienceAndMediaContainer = css`
  position: relative;
`

export const channelIcon = css`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const imageContainer = (canEdit, showInline) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  font-weight: 300;
  box-sizing: border-box;
  height: 68px;
  width: ${showInline ? '50%' : '121px'};
  border: 0.8px solid #cccccc;
  background-color: #ebebeb;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
  ${!canEdit && 'pointer-events: none;'}
  .img {
    height: 18px;
    width: 13px;
  }
  .inputStyle {
    display: none;
  }
  .uploadedImage {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
`

const getDropDownActiveStyle = count => css`
  height: ${count * 68}px;
`

export const dropDownContent = (sourceListActive, count, cardLength) => css`
    height: 0px;
    overflow: hidden;
    margin-left: 19px;
    cursor: pointer;
    z-index: 1;
    transition: all 0.4s ease-out;
    ${sourceListActive && getDropDownActiveStyle(count)}
    ${cardLength === 2 && 'margin-left: 159px;'}
    ${cardLength === 3 && 'margin-left: 292px;'}
    ${cardLength === 4 && 'margin-left: 424px;'}
    ${cardLength === 5 && 'margin-left: 556px;'}
`

export const sourceIconBox = css`
  height: 68px;
  width: 68px;
  border: 0.8px solid #cccccc;
  box-shadow: 0 2px 6px rgba(102, 153, 204, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
`

export const addAudienceIconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -4px;
  left: 0;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
`

const getStyleForAudienceListSection = count => css`
  height: ${70 * count + 8 * (count + 1) + 45}px;
  padding-bottom: 8px;
  border: 0.8px solid #cccccc;
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
`

export const audienceListContainer = (
  cardLength,
  audienceIconClicked,
  audienceCount
) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0 2px 6px -4px #0099ff;
  height: 0px;
  overflow: hidden;
  margin-left: 12px;
  transition: all 0.4s ease-out;
  ${setWidthOfForm(cardLength)}
  ${audienceIconClicked && getStyleForAudienceListSection(audienceCount)}
  @media(min-width: 1441px) {
    margin-left: 24px;
  }
`

export const audienceListSection = (audienceIconClicked, audienceCount) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 0px;
  width: 231.4px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 6px -4px #0099ff;
  transition: all 0.4s ease-out;
  ${audienceIconClicked && getStyleForAudienceListSection(audienceCount)}
  border: 0px;
  box-shadow: 0 2px 6px -4px #ffffff;
`

export const audienceListActionButtonContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 11px 0px 11px;
`

export const audienceListActionButton = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  padding-top: 2px;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  width: 99px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  &.saveButton {
    color: #ffffff;
    background-color: #18a3e2;
  }
`

export const selectedAudienceIconList = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -4px;
  left: 0;
`

export const addedAudienceIconStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  cursor: pointer;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  .additionalSelectedAudiences {
    font-family: BentonSans;
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
  }
`

export const adContentContainer = (
  cardLength,
  expandedView,
  isAdEditable
) => css`
  ${setWidthOfForm(cardLength)}
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  box-shadow: 0 2px 6px -4px #0099ff;
  height: 0px;
  overflow: hidden;
  margin-left: 12px;
  transition: all 0.4s ease-out;
  ${
    expandedView &&
    'height: 460px; border: 0.8px solid #CCCCCC; border-top: 0px;'
  }
  @media(min-width: 1441px) {
    margin-left: 24px;
    ${expandedView && ' margin-top: -2px; padding-top: 20px;'}
  }
`
// ${expandedView && isAdEditable && 'height: 460px;'}

export const adContent = (expandedView, isAdEditable) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #333333;
  font-family: BentonSans;
  font-size: 13px;
  font-weight: 300;
  box-sizing: border-box;
  height: 0px;
  width: 231.4px;
  min-width: 231.4px;
  background-color: #ffffff;
  border-radius: 0px 0px 4px 4px;
  position: relative;
  // box-shadow: 0 2px 6px -4px #0099FF;
  overflow: hidden;
  transition: all 0.4s ease-out;
  padding: 0px 12px 0px 12px;
  ${expandedView && 'height: 460px; padding: 8px 0px'}
`
//${expandedView && isAdEditable && 'height: 694px;'}

export const adActionButton = isAdEditable => css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 38px;
    width: 47px;
    border: 0.8px solid #cccccc;
    box-shadow: 0 2px 6px 0px rgba(102, 153, 204, 0.4);
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
  }
  .edit {
    ${isAdEditable && 'background-color: #EBEBEB;'}
  }
`

export const inputStyle = css`
  height: 34px;
  width: 100%;
  padding-left: 14px;
  border: 0.8px solid #cccccc;
  background-color: #fff;
  outline: none;
  border-radius: 4px;
  margin-top: 2px;
  color: #333333;
  font-family: BentonSans;
  font-weight: 600;
`

export const textAreaStyle = css`
  resize: none;
  padding: 17px 14px;
  height: 64px;
`

export const newsFeedAreaStyle = css`
  resize: none;
  padding: 17px 14px;
  height: 74px;
`

export const adFieldContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
`

export const adName = css`
  margin-top: 24px;
  position: relative;
  .requiredFieldText {
    position: absolute;
    right: 0px;
    top: 0px;
  }
`

export const adDropdownFieldStyle = isAdEditable => css`
  height: 34px;
  padding-left: 14px;
  min-width: 100px;
  color: #333333;
  line-height: 2;
  margin-right: 14px;
  font-weight: 600;
  cursor: pointer;
  ${!isAdEditable && 'pointer-events: none;'}
`

export const adDetailsActionButtonContainer = css`
  margin-top: 27px;
`

export const adDetailsActionButton = css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  padding-top: 2px;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  width: 98px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  &.saveButton {
    color: #ffffff;
    background-color: #18a3e2;
    margin-right: 8px;
  }
`

export const nonEditableAdField = css`
  display: flex;
  align-items: center;
  width: 212px;
  position: relative;
  padding: 12px 12px;
  height: 40px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 1;
  &.adFieldValue {
    background-color: #f5f5f5;
    font-weight: 600;
    .status {
      font-weight: 300;
      margin-right: 4px;
    }
  }
  .requiredFieldText {
    position: absolute;
    right: 12px;
    top: 12px;
  }
`

export const addFormDetails = isAdEditable => css`
  width: 100%;
  padding: 0px 8px;
  overflow-y: auto;
  ${!isAdEditable && 'padding: 0px 2px;'}
`

export const tooltipStyle = css`
  position: absolute;
  font-size: 10px;
  background: #000000a1;
  padding: 5px;
  border-radius: 4px;
  width: max-content;
  color: #fff;
  top: 78px;
`

export const googleImgUploaderContainer = css`
    display: flex;
    width: 135px;
    gap: 4px;
`

export const dimensionStyle = css`
 font-size: 8px;
 position: absolute;
 top: 0;
 font-weight: 500;
`

export const previewImage = css`
width: 50%;
border-radius: 5px;
`

export const imageUploadErrorStyle = css`
  position: absolute;
  bottom: -32px;
  left: 0px;
  margin: 0;
  color: red;
  font-size: 10px;
  width: 100%;
  text-align: center;
`

export const errorBorderStyle = css`
  border: 0.8px solid red !important;
`

export const charCountError = css`
  color: red;
  font-size: 12px;
  margin-top: 8px;
  text-align: right;
`

export const errorMessageStyle = css`
  ${charCountError}
  text-align: unset;
`

export const loaderWrapperStyle = css`
  width: 70px;
  text-align: center;
  &.spinner > div {
    width: 13px;
    height: 13px;
    background-color: #17a3e1;
    border-radius: 100%;
    display: inline-block;

    animation: ${bouncedelay} 1.4s infinite ease-in-out both;
  }

  &.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`
