/** @jsxImportSource @emotion/react */
import React, { useRef, useMemo, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Checkbox from '../../../components/ui/Checkbox/Checkbox'
import FacebookIcon from '../../../assets/images/onboardingDashboard/ads/facebookIcon.png'
import ToggleSwitch from '../../../components/ui/SwitchController'
import {
  adAccountContainer,
  adAccountContainerHeading,
  contentContainerStyle,
  actionButtonContainer,
  actionButton,
  toggleStyle,
  overlayStyle
} from './style'

export const FbAdAccountPopUp = ({
  adAccounts = [],
  showSuccessOption,
  selectedUserAdAccounts,
  setSelectedUserAdAccounts,
  onClose = () => {},
  setShowSuccessOption = () => {},
  handleSubmitFbAccounts = () => {},
  isLoading
}) => {
  const content = (
    <div css={overlayStyle}>
      {!showSuccessOption && adAccounts && adAccounts.length && (
        <div css={adAccountContainer}>
          <div css={adAccountContainerHeading}>
            <div
              style={{
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid #ccc',
                borderRadius: '4px',
                marginRight: 20
              }}
            >
              <img src={FacebookIcon} style={{ objectFit: 'contain' }} />
            </div>
            Pick your Ad Accounts
          </div>
          <div css={contentContainerStyle}>
            <div className='tableHeading'>
              <p className='heading-item'>Ad Account</p>
              <p className='heading-item'>Name</p>
              <p className='heading-item'>Ad Account Id</p>
              <p className='heading-item'>Auto Tracking</p>
            </div>
            {adAccounts.map(({ account_id, id: name, name: adaccountName }) => (
              <div key={account_id} className='accountRow'>
                <p className='account-items'>
                  <Checkbox
                    index={account_id}
                    onChange={event => {
                      if (event.target.checked) {
                        const updatedIds = [
                          ...selectedUserAdAccounts,
                          { account_id, auto_tracking: true }
                        ]
                        setSelectedUserAdAccounts(updatedIds)
                      } else {
                        const accountIds = selectedUserAdAccounts.filter(
                          ({ account_id }) => account_id !== account_id
                        )
                        setSelectedUserAdAccounts(accountIds)
                      }
                    }}
                    checked={selectedUserAdAccounts.some(
                      ({ account_id: id }) => account_id === id
                    )}
                  />
                  {name}
                </p>
                <p className='account-items'>{adaccountName}</p>
                <p className='account-items'>{account_id}</p>
                <ToggleSwitch
                  showPause={false}
                  toggleSwitchState={state => {
                    const itemIndex = selectedUserAdAccounts.findIndex(
                      ({ account_id: id }) => id == account_id
                    )
                    const clonedItems = JSON.parse(
                      JSON.stringify(selectedUserAdAccounts)
                    )
                    if (itemIndex !== -1) {
                      clonedItems.splice(itemIndex, 1, {
                        account_id,
                        auto_tracking: state == 'active' ? true : false
                      })
                    }
                    setSelectedUserAdAccounts(clonedItems)
                  }}
                  state={
                    (
                      selectedUserAdAccounts.find(
                        ({ account_id: id }) => id === account_id
                      ) || {}
                    ).auto_tracking
                      ? 'active'
                      : 'stop'
                  }
                  containerStyle={toggleStyle}
                  style={{
                    position: 'relative',
                    top: 'unset',
                    width: '34px',
                    display: 'inline-flex'
                  }}
                />
              </div>
            ))}
          </div>
          <div css={actionButtonContainer}>
            <button
              css={actionButton(isLoading)}
              onClick={() => onClose(false)}
            >
              Back
            </button>
            <button
              className='logInButton connect-button'
              onClick={() => handleSubmitFbAccounts()}
              css={actionButton(isLoading)}
            >
              Connect
              {isLoading && <div className='loader' />}
            </button>
          </div>
        </div>
      )}
      {showSuccessOption && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '615px',
            height: '400px',
            background: '#18a3e2',
            borderRadius: '8px'
          }}
        >
          <div css={adAccountContainerHeading} style={{ color: '#fff' }}>
            <div
              style={{
                width: 60,
                height: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '0.5px solid #ccc',
                borderRadius: '4px',
                marginRight: 20
              }}
            >
              <img src={FacebookIcon} style={{ objectFit: 'contain' }} />
            </div>
            Your Ad Accounts are successfully connected
          </div>
          <div
            css={actionButtonContainer}
            style={{ padding: 0, marginBottom: 20 }}
          >
            <button
              className='logInButton'
              style={{
                width: 164,
                color: '#333',
                border: '1px solid #CCCCCC',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 0 -2px 2px rgba(102,153,204,0.4)'
              }}
              css={actionButton(isLoading)}
              onClick={() => {
                onClose(false)
                setShowSuccessOption(false)
              }}
            >
              Let's keep going
            </button>
          </div>
        </div>
      )}
    </div>
  )
  return ReactDOM.createPortal(content, document.getElementById('portal-root'))
}

export default FbAdAccountPopUp
