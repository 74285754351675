import { createSlice } from '@reduxjs/toolkit'

import axiosInstance from '../../axiosConfig'

import { mapDataForDataSource } from '../../mapper/onboardingDashboard/segments'

const initialData = {
  segmentColumnNames: [],
  segmentSupportedConditions: [],
  syncReportData: {},
  product: {}
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: initialData,
  reducers: {
    getInitialData (state, action) {
      state.segmentColumnNames = action.payload.segmentColumnNames
      state.segmentSupportedConditions =
        action.payload.segmentSupportedConditions
    },
    getSyncedReport (state, action) {
      state.syncReportData = action.payload.syncData
    },
    updateProduct (state, action) {
      state.product = action.payload
      localStorage.setItem('productInfo', JSON.stringify(action.payload))
    },
    clearSyncReportData (state) {
      state.syncReportData = {}
    }
  }
})

export const loadInitialData = () => {
  return async dispatch => {
    try {
      const [columnNames, suportedConditions] = await Promise.all([
        axiosInstance.get('/onboarding/column_names'),
        axiosInstance.get('/onboarding/supported_conditions')
      ])
      if (columnNames.status === 200 && suportedConditions.status === 200) {
        dispatch(
          onboardingSlice.actions.getInitialData({
            segmentColumnNames: columnNames.data.column_names,
            segmentSupportedConditions:
              suportedConditions.data.supported_conditions
          })
        )
      } else {
        throw new Error('Error obtaining data from source')
      }
    } catch (err) {
      throw new Error(`Error obtaining data from source-${err}`)
    }
  }
}

const fetchSyncReport = async () => {
  const { status, data } = await axiosInstance.get('/onboarding/sync_report')
  if (status === 200 && data && data.sync_finished) {
    return { data, status }
  } else {
    return await fetchSyncReport()
  }
}

export const updateSegments = payload => {
  return async (dispatch, getState) => {
    const {
      onboarding: { product: { id: productId } = {} }
    } = getState()
    const dataToSend = { segments: mapDataForDataSource(payload, productId) }
    const { status } = await axiosInstance.post(
      '/onboarding/create_segments',
      dataToSend
    )
    if (status === 200) {
      const { data: syncData, status } = await fetchSyncReport()
      console.log('syncData', syncData, status)
      if (status === 200) {
        dispatch(
          onboardingSlice.actions.getSyncedReport({
            syncData
          })
        )
      } else {
        throw new Error('Error obtaining data from source')
      }
    } else {
      throw new Error('Error obtaining data from source')
    }
  }
}

// export const getSyncReport = () => {
//   return async dispatch => {
//     const { data, status } = await axiosInstance.get('/onboarding/sync_report')
//     if (status === 200) {
//       console.log('getSyncReport', data)
//     } else {
//       throw new Error('Error obtaining data from source')
//     }
//   }
// }

export const updateUserFbAdAccounts = payload => {
  return async dispatch => {
    return axiosInstance
      .post('/fbs/connect', payload)
      .then(({ data, status }) => ({
        data,
        status
      }))
  }
}

export const queueActions = onboardingSlice.actions

export const { updateProduct, clearSyncReportData } = queueActions

export default onboardingSlice.reducer
