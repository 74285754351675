import facebookIcon from '../assets/images/dashboard/ads/facebookIcon.png'
import instagramIcon from '../assets/images/dashboard/ads/instagramIcon.png'
import googleIcon from '../assets/images/dashboard/ads/googleIcon.png'
import linkedinIcon from '../assets/images/dashboard/ads/linkedinIcon.png'

export const channelSourceList = [
  {
    key: 'facebook',
    className: 'facebookIcon',
    alt: 'facebookIcon',
    src: facebookIcon
  },
  {
    key: 'instagram',
    className: 'instagramIcon',
    alt: 'instagramIcon',
    src: instagramIcon
  },
  {
    key: 'google',
    className: 'googleIcon',
    alt: 'googleIcon',
    src: googleIcon
  },
  {
    key: 'linkedin',
    className: 'linkedinIcon',
    alt: 'linkedinIcon',
    src: linkedinIcon
  }
]

export const campaignDetailsMock = {
  budget: '',
  facebookBudget: '',
  instagramBudget: '',
  googleBudget: '',
  linkedinBudget: '',
  spreadBudgetAcrossChannels: false,
  text: '',
  headline: '',
  newsFeedLinkDescription: '',
  displayLink: '',
  callToAction: '',
  startDate: '',
  endDate: '',
  campaignName: '',
  objective: ''
}

export const CALL_TO_ACTION = [
  'BOOK_TRAVEL',
  'CONTACT_US',
  'DONATE',
  'DONATE_NOW',
  'DOWNLOAD',
  'GET_DIRECTIONS',
  'GO_LIVE',
  'INTERESTED',
  'LEARN_MORE',
  'LIKE_PAGE',
  'MESSAGE_PAGE',
  'RAISE_MONEY',
  'SAVE',
  'SEND_TIP',
  'SHOP_NOW',
  'SIGN_UP',
  'VIEW_INSTAGRAM_PROFILE',
  'INSTAGRAM_MESSAGE',
  'LOYALTY_LEARN_MORE',
  'PURCHASE_GIFT_CARDS',
  'PAY_TO_ACCESS',
  'SEE_MORE',
  'TRY_IN_CAMERA',
  'WHATSAPP_LINK',
  'BOOK_NOW',
  'CHECK_AVAILABILITY',
  'ORDER_NOW',
  'WHATSAPP_MESSAGE',
  'GET_MOBILE_APP',
  'INSTALL_MOBILE_APP',
  'USE_MOBILE_APP',
  'INSTALL_APP',
  'USE_APP',
  'PLAY_GAME',
  'WATCH_VIDEO',
  'WATCH_MORE',
  'OPEN_LINK',
  'NO_BUTTON',
  'LISTEN_MUSIC',
  'MOBILE_DOWNLOAD',
  'GET_OFFER',
  'GET_OFFER_VIEW',
  'BUY_NOW',
  'BUY_TICKETS',
  'UPDATE_APP',
  'BET_NOW',
  'ADD_TO_CART',
  'SELL_NOW',
  'GET_SHOWTIMES',
  'LISTEN_NOW',
  'GET_EVENT_TICKETS',
  'REMIND_ME',
  'SEARCH_MORE',
  'PRE_REGISTER',
  'SWIPE_UP_PRODUCT',
  'SWIPE_UP_SHOP',
  'PLAY_GAME_ON_FACEBOOK',
  'VISIT_WORLD',
  'OPEN_INSTANT_APP',
  'JOIN_GROUP',
  'GET_PROMOTIONS',
  'SEND_UPDATES',
  'INQUIRE_NOW',
  'VISIT_PROFILE',
  'CHAT_ON_WHATSAPP',
  'EXPLORE_MORE',
  'CONFIRM',
  'JOIN_CHANNEL',
  'MAKE_AN_APPOINTMENT',
  'ASK_ABOUT_SERVICES',
  'BOOK_A_CONSULTATION',
  'GET_A_QUOTE',
  'BUY_VIA_MESSAGE',
  'ASK_FOR_MORE_INFO',
  'CHAT_WITH_US',
  'VIEW_PRODUCT',
  'VIEW_CHANNEL',
  'CALL',
  'MISSED_CALL',
  'CALL_NOW',
  'CALL_ME',
  'APPLY_NOW',
  'BUY',
  'GET_QUOTE',
  'SUBSCRIBE',
  'RECORD_NOW',
  'VOTE_NOW',
  'GIVE_FREE_RIDES',
  'REGISTER_NOW',
  'OPEN_MESSENGER_EXT',
  'EVENT_RSVP',
  'CIVIC_ACTION',
  'SEND_INVITES',
  'REFER_FRIENDS',
  'REQUEST_TIME',
  'SEE_MENU',
  'SEARCH',
  'TRY_IT',
  'TRY_ON',
  'LINK_CARD',
  'DIAL_CODE',
  'FIND_YOUR_GROUPS'
]
