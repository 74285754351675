// Define validation rules for different channels
const validationRules = {
  facebook: {
    maxSizeMB: 4000, // 4 GB
    minDurationSec: 1,
    maxDurationSec: 14460, // 241 minutes
    minWidth: 120, // Updated minimum width
    minHeight: 120, // Updated minimum height
    validAspectRatios: [
      { width: 1, height: 1 }, // 1:1
      { width: 4, height: 5 } // 4:5
    ]
  }
}

export const validateURL = value => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      "((([a-zA-Z0-9$-_@.&+!*'(),]|%[0-9a-fA-F]{2})+)(:([a-zA-Z0-9$-_@.&+!*'(),]|%[0-9a-fA-F]{2})+)?@)?" + // user:password@
      '((\\[(|v[0-9a-fA-F]+)\\]?)|(([a-zA-Z0-9-]+\\.)+([a-zA-Z]{2,})))' + // host
      '(:\\d{2,5})?' + // port
      '(\\/[-a-zA-Z0-9%_.~+]*)*' + // path
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
      '(#[-a-zA-Z0-9_]*)?$'
  )
  return urlPattern.test(value)
}

// Generic video validation method
export const validateVideoFile = (file, channel) => {
  return new Promise((resolve, reject) => {
    const rules = validationRules[channel]
    if (!rules) {
      return reject(
        new Error(`No validation rules defined for channel: ${channel}`)
      )
    }

    // Check file size
    if (file.size > rules.maxSizeMB * 1024 * 1024) {
      return reject(new Error('File size exceeds the maximum limit.'))
    }

    const videoElement = document.createElement('video')
    const objectUrl = URL.createObjectURL(file)

    videoElement.src = objectUrl
    videoElement.onloadedmetadata = () => {
      const duration = videoElement.duration
      const width = videoElement.videoWidth
      const height = videoElement.videoHeight

      // Check duration
      if (duration < rules.minDurationSec || duration > rules.maxDurationSec) {
        return reject(
          new Error(
            `Video duration must be between ${rules.minDurationSec} seconds and ${rules.maxDurationSec} seconds.`
          )
        )
      }

      // Check resolution and aspect ratio
      if (width < rules.minWidth || height < rules.minHeight) {
        return reject(
          new Error(
            `Video resolution must be at least ${rules.minWidth}x${rules.minHeight} pixels.`
          )
        )
      }

      // const aspectRatio = width / height
      // const validAspectRatio = rules.validAspectRatios.some(ratio => {
      //   return aspectRatio === ratio.width / ratio.height
      // })

      // if (!validAspectRatio) {
      //   return reject(
      //     new Error('Invalid aspect ratio. Supported ratios are 1:1 and 4:5.')
      //   )
      // }

      // Clean up the URL
      URL.revokeObjectURL(objectUrl)
      resolve(true)
    }

    videoElement.onerror = () => {
      URL.revokeObjectURL(objectUrl)
      reject(new Error('Failed to load video.'))
    }
  })
}
