/** @jsxImportSource @emotion/react */
import React, { useState, useRef, useEffect } from 'react'

import Icon from '@material-ui/core/Icon'
import { validate as uuidValidate } from 'uuid'

import { CALL_TO_ACTION, channelSourceList } from '../../../../../constant/ads'
import Dropdown from '../../../../ui/Dropdown/Dropdown'
import SwitchController from '../../../../ui/SwitchController'
import AudienceCard from '../AudienceCard'

import minusIcon from '../../../../../assets/images/dashboard/audience/minusIcon.png'
import plusIcon from '../../../../../assets/images/dashboard/audience/plusIcon.png'
import addAudienceIcon from '../../../../../assets/images/dashboard/ads/addAudienceIcon.png'
import uploadIcon from '../../../../../assets/images/dashboard/ads/uploadIcon.png'
import trashIcon from '../../../../../assets/images/trash-icon.png'
import editIcon from '../../../../../assets/images/editIcon.png'
import salesforceIcon from '../../../../../assets/images/dashboard/audience/salesforce.png'
import neyoColorIcon from '../../../../../assets/images/neyoMarkColor.svg'
import copySelectedIcon from '../../../../../assets/images/dashboard/ads/copySelectedIcon.png'
import copyUnselectedIcon from '../../../../../assets/images/dashboard/ads/copyUnselectedIcon.png'
import {
  uploadFileToS3FB,
  uploadVideoFileToS3
} from '../../../../../store/dashboard/adsSlice'
import {
  container,
  copyActionIconContainer,
  header,
  minimizeFromLeft,
  minimizeFromRight,
  rightExtensionContainer,
  leftExtensionContainer,
  extensionButton,
  rightExtensionCard,
  leftExtensionCard,
  channelContainer,
  audienceAndMediaContainer,
  channelIcon,
  imageContainer,
  dropDownContent,
  sourceIconBox,
  addAudienceIconStyle,
  audienceListContainer,
  audienceListSection,
  audienceListActionButtonContainer,
  audienceListActionButton,
  selectedAudienceIconList,
  addedAudienceIconStyle,
  adContentContainer,
  adContent,
  adActionButton,
  inputStyle,
  textAreaStyle,
  newsFeedAreaStyle,
  adFieldContainer,
  adName,
  adDropdownFieldStyle,
  adDetailsActionButtonContainer,
  adDetailsActionButton,
  nonEditableAdField,
  addFormDetails,
  tooltipStyle,
  googleImgUploaderContainer,
  dimensionStyle,
  previewImage,
  imageUploadErrorStyle,
  errorBorderStyle,
  errorMessageStyle,
  loaderWrapperStyle
} from './style'
import { useDispatch } from 'react-redux'
import { validateURL, validateVideoFile } from '../../AdCampaign/adsHelper'

const Loader = () => {
  return (
    <div css={loaderWrapperStyle} className='spinner'>
      <div className='bounce1'></div>
      <div className='bounce2'></div>
      <div className='bounce3'></div>
    </div>
  )
}

const REQUIRED_DIMENSION = {
  square_image: { width: 300, height: 300 },
  landscape_image: { width: 600, height: 300 }
}

const getSource = key => {
  const { className, alt, src } = channelSourceList.find(source => {
    return source.key === key
  })
  return <img className={className} alt={alt} src={src} />
}

const getSelectedAudiences = audienceData => {
  return audienceData.filter(audience => audience.isSelected)
}

const getSourceIconBox = source => {
  let imageSource = neyoColorIcon
  if (source === 'salesforce') imageSource = salesforceIcon
  return (
    <img
      className={source}
      alt={source}
      src={imageSource}
      width={19}
      height={13}
    ></img>
  )
}

const BuildAdd = ({
  segmentId,
  adIndex,
  segmentIndex,
  copyIconClicked,
  campaignDetails,
  setCopyIconClicked,
  setCopiedAdData,
  adData,
  segmentLinkedMapper,
  adCampaignData,
  isCampaignEditable = true,
  updateAdDetails = () => {},
  handleAdDelete = () => {},
  handleAdExtensionMinimize = () => {},
  isAdDataValid = true
}) => {
  const [sourceListActive, setSourceListActive] = useState(false)
  const [expandedView, setExpandedView] = useState(false)
  const hiddenFileInput = useRef(null)
  const squareFileInputRef = useRef(null)
  const landscapeFileInputRef = useRef(null)
  const [audienceIconClicked, setAudienceIconClicked] = useState(false)
  const [isAdEditable, setAdEditable] = useState(false)
  const [audienceList, setAudienceList] = useState([])
  const [rightExtensionHoverFlag, setRightExtensionHoverFlag] = useState(false)
  const [rightMinimizeHoverFlag, setRightMinimizeHoverFlag] = useState(false)
  const [leftExtensionHoverFlag, setLeftExtensionHoverFlag] = useState(false)
  const [leftMinimizeHoverFlag, setLeftMinimizeHoverFlag] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [uplaodError, setShowUploadError] = useState(false)
  const [invalidUrl, setInvalidUrl] = useState(false)
  const dispatch = useDispatch()
  const { source, id } = adData || {}
  const prevSourceRef = useRef()
  const [uploadLoader, setShowUploadLoader] = useState(false)

  useEffect(() => {
    if (adData) {
      const { audienceData } = adData || {}
      setAudienceList(audienceData)
    }
  }, [adData])

  useEffect(() => {
    if (
      prevSourceRef.current !== undefined &&
      prevSourceRef.current !== source
    ) {
      updateAdDetails(segmentId, adIndex, {
        url: '',
        landscape_image: '',
        square_image: ''
      })
    }
    prevSourceRef.current = source
  }, [source])

  const [showExtensionMinimizeButtons, setShowExtensionMinimizeButtons] =
    useState(campaignDetails && campaignDetails.id ? false : true)

  const toggleHeightHandler = () => {
    if (sourceListActive) {
      setSourceListActive(sourceListActive => !sourceListActive)
    }
    if (audienceIconClicked) {
      setAudienceIconClicked(prev => !prev)
    }
    setExpandedView(expandedView => !expandedView)
  }

  const selectSourceHandler = key => {
    updateAdDetails(segmentId, adIndex, { source: key })
  }

  const clickSourceHandler = () => {
    if (expandedView) {
      setExpandedView(prev => !prev)
    }
    if (audienceIconClicked) {
      setAudienceIconClicked(prev => !prev)
    }
    setSourceListActive(sourceListActive => !sourceListActive)
  }

  const sourceComponent = () => {
    return channelSourceList.map(({ className, alt, src, key }) => (
      <span
        key={key}
        css={sourceIconBox}
        onClick={() => {
          selectSourceHandler(key)
          setSourceListActive(sourceListActive => !sourceListActive)
        }}
      >
        <img className={className} alt={alt} src={src} />
      </span>
    ))
  }

  const checkImageDimensions = (file, requiredWidth, requiredHeight) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = URL.createObjectURL(file)

      img.onload = () => {
        const isValidDimension =
          img.width === requiredWidth && img.height === requiredHeight
        URL.revokeObjectURL(img.src) // Clean up the URL
        if (isValidDimension) {
          resolve(true) // Image has valid dimensions
        } else {
          resolve(false) // Image has invalid dimensions
        }
      }

      img.onerror = () => {
        URL.revokeObjectURL(img.src) // Clean up the URL
        reject(new Error('Failed to load image.'))
      }
    })
  }

  const uploadFiles = async (file, type) => {
    let urlObj = {}
    try {
      const { url } = await dispatch(uploadFileToS3FB(file, file.name))
      urlObj[type ? type : 'url'] = url
    } catch (error) {
      console.error(`Failed to upload ${file.name}:`, error)
    }
    return urlObj
  }

  const handleVideoUpload = async file => {
    try {
      await validateVideoFile(file, adData.source) // Validate video file properties based on channel
      const { url } = await dispatch(
        uploadVideoFileToS3({ file, key: file.name, channel: adData.source })
      )
      return url
    } catch (error) {
      // Handle validation error
      throw new Error(error.message) // Rethrow the error to be caught in the calling function
    }
  }

  const handleFileChange = async (event, type) => {
    setShowUploadError(false)
    const file = event.target.files[0]
    if (!file) return
    let urlObj = {}
    const isVideo = file.type.includes('video')
    setShowUploadLoader(true)
    if (isVideo) {
      try {
        const videoUrl = await handleVideoUpload(file)
        updateAdDetails(segmentId, adIndex, {
          video_url: videoUrl,
          ad_type: 'video'
        })
        setShowUploadLoader(false)
        return
      } catch (error) {
        setShowUploadError(error.message) // Show validation error message
        setShowUploadLoader(false)
        return
      }
    }
    const { width, height } = REQUIRED_DIMENSION[type] || {}
    if (type) {
      const isValidImage = await checkImageDimensions(file, width, height)
      if (isValidImage) {
        urlObj = await uploadFiles(file, type)
      } else {
        setShowUploadError(
          `Image is not of ${(type.split('_') || [])[0]} format`
        )
      }
    } else {
      urlObj = await uploadFiles(file, type)
    }
    setShowUploadLoader(false)
    updateAdDetails(segmentId, adIndex, {
      ...urlObj
    })
  }

  const handleClick = type => {
    if (!adData.source) return
    if (type === 'square_image') {
      squareFileInputRef.current.click() // Trigger square file input
    } else if (type === 'landscape_image') {
      landscapeFileInputRef.current.click() // Trigger landscape file input
    } else {
      hiddenFileInput.current.click()
    }
  }

  const onAudienceCardSelection = index => {
    const prevAudienceData = JSON.parse(JSON.stringify(audienceList))
    prevAudienceData[index].isSelected = !prevAudienceData[index].isSelected
    setAudienceList(prevAudienceData)
  }

  const saveAudienceSelectionHandler = () => {
    setAudienceIconClicked(prev => !prev)
    updateAdDetails(segmentId, adIndex, {
      audienceData: audienceList
    })
  }

  const cancelAudienceSelectionHandler = () => {
    setAudienceIconClicked(prev => !prev)
    setAudienceList(JSON.parse(JSON.stringify(adData.audienceData)))
  }

  const saveAdDetailsChangesHandler = () => {
    setAdEditable(prev => !prev)
  }

  const cancelAdDetailsChangesHandler = () => {
    setAdEditable(prev => !prev)
  }

  const getThumbnailBoxesForSelectedAudiences = (selectedAudiences = []) => {
    if (!selectedAudiences.length) {
      return (
        <span
          css={addAudienceIconStyle}
          onClick={() => {
            if (sourceListActive) {
              setSourceListActive(sourceListActive => !sourceListActive)
            }
            if (expandedView) {
              setExpandedView(prev => !prev)
            }
            setAudienceIconClicked(prev => !prev)
          }}
        >
          <img
            alt='add-audience-icon'
            src={addAudienceIcon}
            width={18}
            height={19}
          />
        </span>
      )
    } else if (selectedAudiences.length > 4) {
      return (
        <div css={selectedAudienceIconList}>
          {[0, 1, 2, 3].map(id => (
            <span
              css={addedAudienceIconStyle}
              key={`${selectedAudiences[id].id}${id}`}
              onClick={() => {
                if (sourceListActive) {
                  setSourceListActive(sourceListActive => !sourceListActive)
                }
                if (expandedView) {
                  setExpandedView(prev => !prev)
                }
                setAudienceIconClicked(prev => !prev)
              }}
            >
              {getSourceIconBox(selectedAudiences[id].source)}
            </span>
          ))}
          <span
            css={addedAudienceIconStyle}
            onClick={() => {
              if (sourceListActive) {
                setSourceListActive(sourceListActive => !sourceListActive)
              }
              setAudienceIconClicked(prev => !prev)
            }}
          >
            <label className='additionalSelectedAudiences'>{`+${
              selectedAudiences.length - 4
            }`}</label>
          </span>
        </div>
      )
    } else {
      return (
        <div css={selectedAudienceIconList}>
          {selectedAudiences.map((audience, index) => (
            <span
              css={addedAudienceIconStyle}
              key={`${audience.id}${index}`}
              onClick={() => {
                if (sourceListActive) {
                  setSourceListActive(sourceListActive => !sourceListActive)
                }
                setAudienceIconClicked(prev => !prev)
              }}
            >
              {getSourceIconBox(audience.source)}
            </span>
          ))}
        </div>
      )
    }
  }

  const showLeftExtensionButton = showExtensionMinimizeButtons => {
    const leftMostSegmentId = adData.createdFromSegments[0]
    if (
      showExtensionMinimizeButtons &&
      segmentLinkedMapper[leftMostSegmentId] &&
      segmentLinkedMapper[leftMostSegmentId].prev &&
      (!adCampaignData[segmentLinkedMapper[leftMostSegmentId].prev][adIndex] ||
        !adCampaignData[segmentLinkedMapper[leftMostSegmentId].prev][adIndex]
          .id)
    )
      return true
    return false
  }

  const showRightExtensionButton = showExtensionMinimizeButtons => {
    const rightMostSegmentId =
      adData.createdFromSegments[adData.createdFromSegments.length - 1]
    if (
      showExtensionMinimizeButtons &&
      segmentLinkedMapper[rightMostSegmentId] &&
      segmentLinkedMapper[rightMostSegmentId].next &&
      (!adCampaignData[segmentLinkedMapper[rightMostSegmentId].next][adIndex] ||
        !adCampaignData[segmentLinkedMapper[rightMostSegmentId].next][adIndex]
          .id)
    )
      return true
    return false
  }

  const handleOnChange = (key, value) => {
    updateAdDetails(segmentId, adIndex, { [key]: value })
  }

  const handleCopyAction = action => {
    if (action === 'copy') {
      setCopyIconClicked(true)
      setCopiedAdData(adData)
    }
    if (action === 'close') {
      setCopyIconClicked(false)
      setCopiedAdData(null)
    }
    if (expandedView) {
      setExpandedView(prev => !prev)
    }
  }

  const handleMouseEnter = () => {
    if (adData.source) return
    setShowTooltip(true)
  }

  const handleMouseOut = () => {
    setShowTooltip(false)
  }

  const imageUploader = () => {
    const {
      source = '',
      url = '',
      square_image: square = '',
      landscape_image: landscape = '',
      id,
      ad_type: adType,
      video_url: videoUrl = ''
    } = adData || {}
    const canEdit = uuidValidate(id)
    if (source !== 'google') {
      return (
        <div
          css={imageContainer(canEdit)}
          onClick={() => {
            handleClick()
          }}
        >
          {showTooltip && (
            <div css={tooltipStyle}>Please select source first!</div>
          )}
          {!(url || videoUrl) && (
            <>
              <input
                type='file'
                accept='image/*, video/mp4'
                ref={hiddenFileInput}
                onChange={event => handleFileChange(event)}
                className='inputStyle'
              />
              {uploadLoader ? (
                <Loader showText={false} />
              ) : (
                <img alt='upload-icon' src={uploadIcon} />
              )}
            </>
          )}
          {(url || videoUrl) && (
            <>
              {canEdit ? (
                <input
                  type='file'
                  accept='image/*, video/mp4'
                  ref={hiddenFileInput}
                  style={{ display: 'none' }}
                  onChange={event => handleFileChange(event)}
                  className='inputStyle'
                />
              ) : null}
              {uploadLoader ? (
                <Loader showText={false} />
              ) : adType === 'video' ? (
                <video
                  className='uploadedVideo'
                  src={videoUrl}
                  loop
                  autoPlay
                  muted
                  style={{
                    cursor: canEdit ? 'pointer' : '',
                    maxWidth: '100%'
                  }}
                  onClick={canEdit ? () => handleClick() : () => {}}
                />
              ) : (
                <img
                  className='uploadedImage'
                  alt='upload-icon'
                  src={url}
                  style={{ cursor: canEdit ? 'pointer' : '' }}
                  onClick={canEdit ? () => handleClick() : () => {}}
                />
              )}
            </>
          )}
        </div>
      )
    }

    return (
      <div css={googleImgUploaderContainer}>
        {!square ? (
          <div
            css={imageContainer(canEdit, true)}
            onClick={() => handleClick('square_image')}
          >
            <p css={dimensionStyle}>
              {REQUIRED_DIMENSION['square_image'].width} x{' '}
              {REQUIRED_DIMENSION['square_image'].height}
            </p>
            <input
              type='file'
              ref={squareFileInputRef}
              onChange={event => handleFileChange(event, 'square_image')}
              className='inputStyle'
            />
            {uploadLoader ? (
              <Loader showText={false} />
            ) : (
              <img alt='upload-icon' src={uploadIcon} />
            )}
          </div>
        ) : (
          <>
            {canEdit ? (
              <input
                type='file'
                style={{ display: 'none' }}
                ref={squareFileInputRef}
                onChange={event => handleFileChange(event, 'square_image')}
                className='inputStyle'
              />
            ) : null}
            {uploadLoader ? (
              <Loader showText={false} />
            ) : (
              <img
                css={previewImage}
                alt='upload-icon'
                src={square}
                style={{ cursor: canEdit ? 'pointer' : '' }}
                onClick={canEdit ? () => handleClick('square_image') : () => {}}
              />
            )}
          </>
        )}
        {!landscape ? (
          <div
            css={imageContainer(canEdit, true)}
            onClick={() => handleClick('landscape_image')}
          >
            <p css={dimensionStyle}>
              {REQUIRED_DIMENSION['landscape_image'].width} x{' '}
              {REQUIRED_DIMENSION['landscape_image'].height}
            </p>
            <input
              type='file'
              ref={landscapeFileInputRef}
              onChange={event => handleFileChange(event, 'landscape_image')}
              className='inputStyle'
            />
            {uploadLoader ? (
              <Loader showText={false} />
            ) : (
              <img alt='upload-icon' src={uploadIcon} />
            )}
          </div>
        ) : (
          <>
            {canEdit ? (
              <input
                type='file'
                style={{ display: 'none' }}
                ref={landscapeFileInputRef}
                onChange={event => handleFileChange(event, 'landscape_image')}
                className='inputStyle'
              />
            ) : null}
            {uploadLoader ? (
              <Loader showText={false} />
            ) : (
              <img
                css={previewImage}
                alt='upload-icon'
                style={{ cursor: canEdit ? 'pointer' : '' }}
                src={landscape}
                onClick={
                  canEdit ? () => handleClick('landscape_image') : () => {}
                }
              />
            )}
          </>
        )}
      </div>
    )
  }
  return (
    <div css={container}>
      <div
        css={header(
          expandedView,
          audienceIconClicked,
          rightMinimizeHoverFlag,
          leftMinimizeHoverFlag,
          adData.createdFromSegments.length,
          isAdDataValid
        )}
        onMouseEnter={() => {
          !copyIconClicked &&
            isCampaignEditable &&
            setShowExtensionMinimizeButtons(true)
        }}
        onMouseLeave={() => {
          isCampaignEditable && setShowExtensionMinimizeButtons(false)
        }}
      >
        <div
          css={copyActionIconContainer(copyIconClicked)}
          onClick={() => handleCopyAction('close')}
        >
          <img
            src={copySelectedIcon}
            alt='copy-selected-icon'
            width={50}
            height={45}
          />
        </div>
        <span className='toggleHeight' onClick={toggleHeightHandler}>
          <img
            alt='expandIcon'
            src={expandedView ? minusIcon : plusIcon}
            height={24}
            width={24}
          />
        </span>
        {showExtensionMinimizeButtons && adData.createdFromSegments.length > 1 && (
          <div css={minimizeFromLeft(leftMinimizeHoverFlag)}>
            <Icon className='plusIcon'>remove</Icon>
          </div>
        )}
        <div className='channelMediaContainer'>
          <div css={channelContainer}>
            <div css={channelIcon} onClick={clickSourceHandler}>
              {(adData.source && getSource(adData.source)) || (
                <span>Channel</span>
              )}
            </div>
            {adData.source && <SwitchController />}
          </div>
          <div
            css={audienceAndMediaContainer}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseOut}
          >
            {imageUploader()}
            {getThumbnailBoxesForSelectedAudiences(
              getSelectedAudiences(adData.audienceData)
            )}
          </div>
        </div>
        {showExtensionMinimizeButtons && adData.createdFromSegments.length > 1 && (
          <div css={minimizeFromRight(rightMinimizeHoverFlag)}>
            <Icon className='plusIcon'>remove</Icon>
          </div>
        )}
        <>
          {showLeftExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(adData.createdFromSegments.length)}
              className='leftExtension'
              onMouseEnter={() => {
                setLeftExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setLeftExtensionHoverFlag(false)
              }}
              onClick={() =>
                handleAdExtensionMinimize(
                  adCampaignData,
                  segmentId,
                  segmentIndex,
                  adIndex,
                  'leftExtension'
                )
              }
            >
              <label>{'<'}</label>
            </div>
          )}

          {showExtensionMinimizeButtons &&
            adData.createdFromSegments.length > 1 && (
              <div
                css={extensionButton(adData.createdFromSegments.length)}
                className='leftMinimize'
                onMouseEnter={() => {
                  setLeftMinimizeHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setLeftMinimizeHoverFlag(false)
                }}
                onClick={() =>
                  handleAdExtensionMinimize(
                    adCampaignData,
                    segmentId,
                    segmentIndex,
                    adIndex,
                    'leftMinimize'
                  )
                }
              >
                <label>{'>'}</label>
              </div>
            )}
          <div css={leftExtensionContainer(adData.createdFromSegments.length)}>
            <div
              css={leftExtensionCard(
                leftExtensionHoverFlag,
                adData.createdFromSegments.length
              )}
            >
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
        </>
        <>
          {showExtensionMinimizeButtons &&
            adData.createdFromSegments.length > 1 && (
              <div
                css={extensionButton(adData.createdFromSegments.length)}
                className='rightMinimize'
                onMouseEnter={() => {
                  setRightMinimizeHoverFlag(true)
                }}
                onMouseLeave={() => {
                  setRightMinimizeHoverFlag(false)
                }}
                onClick={() =>
                  handleAdExtensionMinimize(
                    adCampaignData,
                    segmentId,
                    segmentIndex,
                    adIndex,
                    'rightMinimize'
                  )
                }
              >
                <label>{'<'}</label>
              </div>
            )}
          {showRightExtensionButton(showExtensionMinimizeButtons) && (
            <div
              css={extensionButton(adData.createdFromSegments.length)}
              className='rightExtension'
              onMouseEnter={() => {
                setRightExtensionHoverFlag(true)
              }}
              onMouseLeave={() => {
                setRightExtensionHoverFlag(false)
              }}
              onClick={() =>
                handleAdExtensionMinimize(
                  adCampaignData,
                  segmentId,
                  segmentIndex,
                  adIndex,
                  'rightExtension'
                )
              }
            >
              <label>{'>'}</label>
            </div>
          )}
          <div css={rightExtensionContainer(adData.createdFromSegments.length)}>
            <div
              css={rightExtensionCard(
                rightExtensionHoverFlag,
                adData.createdFromSegments.length
              )}
            >
              <Icon className='plusIcon'>add</Icon>
            </div>
          </div>
        </>
        {uplaodError ? <p css={imageUploadErrorStyle}>{uplaodError}</p> : null}
      </div>
      <div
        css={dropDownContent(
          sourceListActive,
          channelSourceList.length,
          adData.createdFromSegments.length
        )}
      >
        {sourceComponent()}
      </div>
      <div
        css={audienceListContainer(
          adData.createdFromSegments.length,
          audienceIconClicked,
          adData.audienceData.length
        )}
      >
        <div
          css={audienceListSection(
            audienceIconClicked,
            adData.audienceData.length
          )}
        >
          {audienceList.map((audience, index) => {
            return (
              <AudienceCard
                key={`${audience.id}${index}`}
                index={index}
                data={audience}
                onSelection={onAudienceCardSelection}
              />
            )
          })}
          {isCampaignEditable && (
            <div css={audienceListActionButtonContainer}>
              <button
                className='saveButton'
                css={audienceListActionButton}
                onClick={saveAudienceSelectionHandler}
              >
                Save
              </button>
              <button
                css={audienceListActionButton}
                onClick={cancelAudienceSelectionHandler}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        css={adContentContainer(
          adData.createdFromSegments.length,
          expandedView,
          isAdEditable
        )}
      >
        <div css={adContent(expandedView, isAdEditable)}>
          {isAdEditable && (
            <>
              <div css={addFormDetails}>
                <div css={[adFieldContainer, adName]}>
                  <label className='requiredFieldText'>* Required</label>
                  <label>Ad Name*</label>
                  <input
                    disabled={!isAdEditable}
                    value={adData.adName}
                    css={inputStyle}
                    onChange={({ target: { value } = {} }) =>
                      handleOnChange('adName', value)
                    }
                  ></input>
                </div>
                <div css={[adFieldContainer]}>
                  <label>Objective</label>
                  <Dropdown
                    value={
                      (adData.objective &&
                        adData.objective === 'OUTCOME_AWARENESS' &&
                        'REACH') ||
                      (adData.objective &&
                        adData.objective === 'OUTCOME_LEADS' &&
                        'LEAD_GENERATION') ||
                      ''
                    }
                    onOptionSelected={value => {
                      if (value === 'REACH') {
                        handleOnChange('objective', 'OUTCOME_AWARENESS')
                      } else {
                        handleOnChange('objective', 'OUTCOME_LEADS')
                      }
                    }}
                    convertToTitleCase
                    options={['REACH', 'LEAD_GENERATION']}
                    style={adDropdownFieldStyle(isAdEditable)}
                  />
                </div>
                <div css={[adFieldContainer]}>
                  <label>Text*</label>
                  <textarea
                    value={adData.text}
                    disabled={!isAdEditable}
                    css={[inputStyle, textAreaStyle]}
                    onChange={({ target: { value } = {} }) =>
                      handleOnChange('text', value)
                    }
                  />
                </div>
                <div css={[adFieldContainer]}>
                  <label>Headline*</label>
                  <input
                    disabled={!isAdEditable}
                    css={inputStyle}
                    value={adData.headline}
                    onChange={({ target: { value } = {} }) =>
                      handleOnChange('headline', value)
                    }
                  ></input>
                </div>
                <div css={[adFieldContainer]}>
                  <label>Newsfeed Link Description*</label>
                  <textarea
                    disabled={!isAdEditable}
                    css={[inputStyle, newsFeedAreaStyle]}
                    value={adData.newsFeedLinkDescription}
                    onChange={({ target: { value } = {} }) =>
                      handleOnChange('newsFeedLinkDescription', value)
                    }
                  />
                </div>
                <div css={[adFieldContainer]}>
                  <label>Display Link*</label>
                  <input
                    disabled={!isAdEditable}
                    css={[inputStyle, invalidUrl && errorBorderStyle]}
                    value={adData.displayLink}
                    onChange={({ target: { value } = {} }) => {
                      const isValidURL = validateURL(value)
                      if (isValidURL || value === '') {
                        setInvalidUrl(false)
                      } else {
                        setInvalidUrl(true)
                      }
                      handleOnChange('displayLink', value)
                    }}
                  />
                  <div css={errorMessageStyle}>
                    {invalidUrl ? 'Please enter valid url' : null}
                  </div>
                </div>
                <div css={[adFieldContainer]}>
                  <label>Call to Action</label>
                  <Dropdown
                    value={adData.callToAction}
                    options={CALL_TO_ACTION}
                    convertToTitleCase
                    style={adDropdownFieldStyle(isAdEditable)}
                    onOptionSelected={value =>
                      handleOnChange('callToAction', value)
                    }
                  />
                </div>
              </div>
              <div css={adDetailsActionButtonContainer}>
                <button
                  className='saveButton'
                  css={adDetailsActionButton}
                  onClick={saveAdDetailsChangesHandler}
                >
                  Save
                </button>
                <button
                  css={adDetailsActionButton}
                  onClick={cancelAdDetailsChangesHandler}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
          {!isAdEditable && (
            <>
              {isCampaignEditable && (
                <div css={adActionButton(isAdEditable)}>
                  <span
                    className='edit'
                    onClick={() => setAdEditable(prev => !prev)}
                  >
                    <img src={editIcon} alt='edit-icon' />
                  </span>
                  <span>
                    <img
                      src={copyUnselectedIcon}
                      alt='copy-icon'
                      width={19}
                      height={21}
                      onClick={() => handleCopyAction('copy')}
                    />
                  </span>
                  <span onClick={() => handleAdDelete(segmentId, adIndex)}>
                    <img src={trashIcon} alt='trash-icon' />
                  </span>
                </div>
              )}
              <div css={addFormDetails(isAdEditable)}>
                <div css={[nonEditableAdField]} className='adFieldValue'>
                  <label className='status'>Status:</label>
                  <label>{adData.status}</label>
                </div>
                <div css={[nonEditableAdField]}>
                  <label className='requiredFieldText'>* Required</label>
                  <label>Ad Name*</label>
                </div>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.adName}
                </label>
                <label css={[nonEditableAdField]}>Objective</label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.objective}
                </label>

                <label css={[nonEditableAdField]}>Text*</label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.text}
                </label>
                <label css={[nonEditableAdField]}>Headline*</label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.headline}
                </label>

                <label css={[nonEditableAdField]}>
                  Newsfeed Link Description*
                </label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.newsFeedLinkDescription}
                </label>

                <label css={[nonEditableAdField]}>Display Link*</label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.displayLink}
                </label>

                <label css={[nonEditableAdField]}>Call to Action</label>
                <label css={[nonEditableAdField]} className='adFieldValue'>
                  {adData.callToAction}
                </label>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BuildAdd
