import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://api.neyo.ai',
  timeout: 200000
})

instance.interceptors.request.use(function (config) {
  const { url } = config || {}
  if (url !== '/login') {
    let token = localStorage.getItem('token')
    config.headers['Authorization'] = `${JSON.parse(token)}`
  }

  return config
})

export default instance
