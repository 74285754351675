import { css, keyframes } from '@emotion/react'

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`

export const adAccountContainer = css`
  width: 895px;
  height: 400px;
  background: #fff;
  box-shadow: 2px 2px 12px 2px #2196f338;
  border-radius: 8px;
`

export const adAccountContainerHeading = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 20px;
  border-radius: 8px 8px 0 0;
  font-size: 21px;
  color: #333;
  font-weight: 300;
  margin-top: 20px;
`

export const contentContainerStyle = css`
  margin: 20px;
  overflow-y: auto;
  height: 216px;
  .tableHeading {
    display: flex;
    padding: 12px;
    gap: 10px;
    background: #f5f5f5;
    border: 1px solid #ccc;
    .heading-item {
      width: 33.33%;
      max-width: 210px;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: #666;
    }
  }

  .accountRow {
    padding: 18px 12px;
    border: 1px solid #ccc;
    border-top: 0;
    gap: 10px;
    .account-items {
      margin: 0;
      width: 33.33%;
      max-width: 210px;
      display: inline-block;
    }
  }
`

export const actionButtonContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 11px 0px 11px;
`

export const actionButton = loading => css`
  color: #333333;
  font-family: BentonSans;
  font-size: 12px;
  padding-top: 2px;
  cursor: pointer;
  box-sizing: border-box;
  height: 38px;
  width: 99px;
  border: 0.8px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 6px -2px rgba(102, 153, 204, 0.4);
  position: relative;
  &.logInButton {
    color: #ffffff;
    background-color: #18a3e2;
    margin-left: 12px;
  }
  ${
    loading &&
    `opacity: 0.6;
    pointer-events: none;
    &.connect-button {
      text-align: left;
    }
    `
  }
  .loader {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    animation: ${spinAnimation} 1s linear infinite;
  }
`

export const toggleStyle = css`
  div {
    flex: 0 0 50%;
  }
`

export const overlayStyle = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  cursor: pointer;
`
